
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie'
import $ from 'jquery';
import {SetChangeLanguage} from '../../../actions/Utils';
import { connect } from 'react-redux';

const LangSelector = (props) => {
  const { i18n } = useTranslation();
  const cookie_lang = Cookies.get('lang');
  const [selectedLang, setSelectedLang] = useState(cookie_lang?cookie_lang:'bn');
 
  const changeLanguage = (lan) => {
    console.log("hello valo event=",lan);
    setSelectedLang(lan);
    i18n.changeLanguage(lan);
    Cookies.set('lang', lan);
    props.SetChangeLanguage({lang:lan});
    
    if(lan == 'bn') {
        if(!$('body').hasClass('content_bangla')){
            $('body').addClass('content_bangla');
        }
    }
    else {
        if($('body').hasClass('content_bangla')){
            $('body').removeClass('content_bangla');
        }
    }
  }
 
  return (
    <div className='language'>

      {selectedLang === 'en' &&<a  data_lan="en" onClick={()=>changeLanguage('bn')} className="btn">বাংলা</a>}
      {selectedLang === 'bn' &&<a  data_lan="bn" onClick={()=>changeLanguage('en')} className="btn">English</a>}

      {/* <label className="mr10"><input type="radio" value="en" name="language" checked={selectedLang === 'en'} /> English</label>
      <label><input type="radio" value="zh-Hant" name="language" checked={selectedLang === 'zh-Hant'} /> Chinese (Traditional)</label> */}
    </div>
  )
}
 

const mapDispatchToProps = (dispatch) =>({
  SetChangeLanguage : (requestData)=>{dispatch(SetChangeLanguage(requestData))},
})

export default connect(null, mapDispatchToProps)(LangSelector);