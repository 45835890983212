import React, { Fragment } from 'react';
import { image_url } from '../../config/Api';
// import defaultBanner from '../../img/banner/bg3.png';
import LangData from '../common/LangData';
import { host_name } from '../../config/Api';
export default ({ title, menu_name, banner }) => {
    return (
        <Fragment>

            <section class="inner-header">
                <img src={banner ? image_url + banner : `${host_name}img/innerbaner.png`} alt=""></img>
                <h1>About Us</h1>
                <div class="inner-breadcrumb">
                    <ul class="nav justify-content-center">
                        <li><a href="#">Home</a></li>
                        <li>{title} </li>
                    </ul>
                </div>
                <div class="overlay"></div>
            </section>
            
            

           
        </Fragment>
    )
}
