import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter,Link } from "react-router-dom";
import { pageSectionContent,PageContent, getSetting, setPathLayout, getPopleDetails } from "../../actions/Utils";

import Htmlcontent from '../../components/Htmlcontent';
import BreadCrumbs from '../../components/page/BreadCrumbs';
import { image_url, host_name } from '../../config/Api';
import BannerLeft from '../page/BannerLeft';


class PeopleDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            path: '',
            contents: null,
            page_content:{},
            content_load: false
        };
    }
    componentDidMount = () => {
        // alert(this.props.appContext.state.peopleTabSelected);
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        const { params } = this.props.match;
        this.props.getPopleDetails({id: params.id, slug: params.slug }).then((data) => {
            console.log("pageSectionContent =", data);
            this.setState({
                contents: data,
                content_load: true
            })
            // alert(data.committee_type)
            this.props.appContext.setState({
                peopleTabSelected: data.committee_type
            })
        });

        const slug = 'people';

        console.log('slug =',slug);

        this.props.PageContent(slug).then((res)=>{

        });

    }

    setPathLayout = (parms) => {
        this.props.setPathLayout(parms);
    }
    getSetting = async () => {
        let data = await this.props.getSetting();
    }
    static getDerivedStateFromProps(props) {
        return {
            path: props.utils.path,
            header: props.header,
            container: props.container,
            footer: props.footer,
            layout_id: props.utils.layout_id,
            lang: props.lang
        };
    }


    render() {

        // console.log("common state =", this.state)
        const { contents, content_load } = this.state;

        const {page_data}=this.props.page_content.content;


        console.log('page_data=', page_data);

        return (
            <Fragment>
                {/* <BannerLeft title="People" menu_name={''} banner={''} /> */}
                
                
                {/* <section class="inner-header">
                    <img src={`${host_name}img/innerbaner.png`} alt=""></img>
                    <h1>People</h1>
                    <div class="inner-breadcrumb">
                        <ul class="nav justify-content-center">
                        <li> <Link to={{ pathname: `/home` }} >Home</Link></li>
                        <li>  <Link to={{ pathname: `/people` }} >People</Link></li>
                            {contents.name ? <li>{contents.name}</li>:''}
                        </ul>
                    </div>
                    <div class="overlay"></div>
                </section> */}
                {page_data && <Fragment>
                
                {contents ? <Fragment>

                    {page_data && <Fragment>

                    <section class="inner-header">
                        <img src={page_data.image ? image_url + page_data.image : `${host_name}img/innerbaner.png`} alt=""></img>
                        <h1>{page_data.title}</h1>
                        <div class="inner-breadcrumb">
                            <ul class="nav justify-content-center">
                                <li><Link to={{ pathname: `/home` }} >Home</Link></li>
                                <li><Link to={{ pathname: `${page_data.slug}` }} >{page_data.title}</Link></li>
                                
                                {contents.name ? <li>{contents.name}</li>:''}
                            </ul>
                        </div>
                        <div class="overlay"></div>
                    </section>

                    </Fragment> }

                


                    <div class="mb-5 people people-details">
                        <div class="container">
                            <div class="people-single">
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="people-image">
                                            {contents.image_long &&<Fragment>
                                                
                                                <img src={`${image_url}${contents.image_long}`} alt="" />
                                            </Fragment>}
                                            
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="people-content">
                                        {contents.name &&<Fragment>
                                            <h3>{contents.name}</h3>
                                        </Fragment>}
                                        {contents.designation &&<Fragment>
                                            <h5>{contents.designation}</h5>
                                        </Fragment>}
                                        
                                        
                                            {contents.tor &&<Fragment>
                                            <Htmlcontent data={contents.tor} />
                                            </Fragment>}
                                            
                                            <div class="people-social">
                                                {contents.facebook && <a target='_blank' href={contents.facebook}><i className="fab fa-facebook-f"></i>
                                                                                
                                                </a>}

                                                {contents.linkedin && <a target='_blank' href={contents.linkedin}><i className="fab fa-linkedin-in"></i> 
                                                                               
                                                </a>}
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>


                </Fragment> : ''}
                
                </Fragment>}

            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    page_content:state.contents.page_content,
    utils: state.utils,
    layout_id: state.utils.layout_id,
    setting: state.utils.setting,
    lang: state.utils.lang

})

const mapDispatchToProps = dispatch => ({
    getSetting: () => dispatch(getSetting()),
    setPathLayout: (requestData) => dispatch(setPathLayout(requestData)),
    PageContent: (requestData) => dispatch(PageContent(requestData)),
    pageSectionContent: (requestData) => dispatch(pageSectionContent(requestData)),
    getPopleDetails: (requestData) => dispatch(getPopleDetails(requestData)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PeopleDetails));