import $ from 'jquery';
import qs from 'qs';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
    sendContactus, setLayout, setPath, setPathLayout,
    
    careerAdd,
    
} from '../../actions/Utils';
import axiosInstance, { version } from '../../config/Api';

import DatePicker from "react-datepicker";
import { DateInput } from '../contactus/Dateinput';
import "react-datepicker/dist/react-datepicker.css";
import Tabs, { Tab } from 'react-best-tabs';
import 'react-best-tabs/dist/index.css';
import { withRouter } from 'react-router-dom';
import LangData from '../common/LangData'
import Cookies from 'js-cookie'
import axios from 'axios';

class CareerForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            validator: {
                name:'',
                phone: '',
                email:'',
                designation_type:'',
                position_type:'',
                doc_file: '',
                
            },
            message: '',
            message_type: '',
            formdata: {
                
                doc_file: null
            },
            api_call:false

        };
        this.refs = React.createRef();

        this.handleDateChange = this.handleDateChange.bind(this);
    }

    handleMessageClose = () => {
        this.setState({ message: '', message_type: '' })
    }

    handleDateChange(date) {
        this.setState({
            startDate: date
        })
    }

    onFileChange = event => {

        // Update the state
        let doc_file = { doc_file: event.target.files[0] };
        let state_formdata = this.state.formdata;
        this.setState({ formdata: { ...state_formdata, ...doc_file } });

    };

    componentDidMount = () => {
        

    }



    handleSubmit = () => {

        const { formdata, validator } = this.state;
        const contents = this.props.data.contents;

        let formUserData = new FormData();

        
        formUserData.append('name', this.name.value);
        formUserData.append('phone', this.phone.value);
        formUserData.append('email', this.email.value);
        formUserData.append('designation_type', this.designation_type.value);
        formUserData.append('position_type', this.position_type.value);
        

        if (this.state.formdata.doc_file) {
            formUserData.append(
                "doc_file",
                this.state.formdata.doc_file,
                this.state.formdata.doc_file.name
            );
        }



        // this.handlePayOnline(userFormData);


        const userFormData = {
            
            name: this.name.value,
            phone: this.phone.value,
            email: this.email.value,
            designation_type: this.designation_type.value,
            position_type: this.position_type.value,
            doc_file: this.doc_file.value,
            
        }

        // return false;
        const resetValidator = {
            name: '',
            phone: '',
            email: '',
            designation_type: '',
            position_type: '',
            
            doc_file: '',
        }
        var validation = {};

        if (userFormData.name == '') {
            validation.name = 'This field is required.';
        }

        if (userFormData.phone == '') {
            validation.phone = 'This field is required.';
        }

        if (userFormData.email == '') {
            validation.email = 'This field is required.';
        }

        if (userFormData.designation_type == '') {
            validation.designation_type = 'This field is required.';
        }

        if (userFormData.position_type == '') {
            validation.position_type = 'This field is required.';
        }
       


        // if (userFormData.contact_linkedin == '') {
        //     validation.contact_linkedin = 'This field is required.';
        // }
        // if (userFormData.pitch_deck == '') {
        //     validation.pitch_deck = 'This field is required.';
        // }
        // if (userFormData.doc_file == '') {
        //     validation.doc_file = 'This field is required.';
        // }

        if (Object.keys(validation).length) {
            this.setState({ validator: { ...resetValidator, ...validation }, message: '' });
        }
        else {
            var error_validate = {};
            this.setState({api_call:true});
            this.props.careerAdd(formUserData).then((res) => {

                // $("#dateofbirth").val('');

                if (res.status == 'success') {
                    $('#formid')[0].reset();

                    this.setState({
                        message: res.message, message_type: 'success', validator: {
                            ...resetValidator
                        },
                        formdata: {
                            
                            doc_file: null
                        },
                        api_call:false
                    })
                }
                else {
                    this.setState({ message: res.message, message_type: 'danger', validator: { ...resetValidator },api_call:false })
                }
            })
                .catch(error => {
                    console.log("Hello Policy form =", error);
                    if (error.response) {
                        var html = '';
                        if (Object.keys(error.response.data).length) {
                            for (const [key, value] of Object.entries(error.response.data.errors)) {
                                console.log(`${key} ${value}`); // "a 5", "b 7", "c 9"
                                html += value + '</br>';
                                if (typeof validator[key] != "undefined") {
                                    // error_validate[key] = `${value}`;
                                    error_validate[`${key}`] = `${value}`;
                                    // Object.assign(error_validate, {key: value});
                                }

                            }

                        }
                        // console.log(' error.response.data ',error.response.data);
                        // console.log(' error.response.status ',error.response.status);
                        // console.log('error.response.headers ',error.response.headers);
                    }

                    console.log("error_validate =", error_validate);
                    console.log("validator =", validator);
                    this.setState({ validator: { ...validation, ...error_validate },api_call:false })
                })
        }
    }






    handleRadio = (name, value) => {
        console.log('name =', name, 'value =', value);
        var question = {};
        if (name == 'is_incorporated') {
            question = { is_incorporated: value };
        }

        else {
            question = {};
        }

        this.setState({
            formdata: {
                ...this.state.formdata,
                ...question
            }
        })
    }




    dateFormate = (date) => {

        var today = new Date(date);
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        return today = yyyy + '/' + mm + '/' + dd;

    }



    render() {

        // const contents = this.props.data.content;
        const { validator, formdata } = this.state;

        
        const designationdata = this.props.data.designationtype;
        const positiondata= this.props.data.positiontype;
        console.log('designationdata = ',designationdata);
        console.log('positiondata = ',positiondata);

        // console.log("validator", validator)
        // console.log("formdata", formdata)
        const lang = this.props.lang;

        return (
            <Fragment>


                <form action="#" id="formid" enctype="multipart/form-data">



                    {(this.state.message != '' && this.state.message_type == 'success') && <div className="col-sm-12" style={{ marginTop: '10px' }}>
                        <div className="alert  alert-success alert-dismissible fade show" role="alert">
                            {this.state.message}
                            <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => this.handleMessageClose()}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>}
                    {(this.state.message != '' && this.state.message_type == 'danger') && <div className="col-sm-12" style={{ marginTop: '10px' }}>
                        <div className="alert  alert-danger alert-dismissible fade show" role="alert">
                            {this.state.message}
                            <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => this.handleMessageClose()}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>}


                    
                    <div className='row'>
                        <div class="col-md-6">
                            {/* <label>Full Name <span>*</span></label> */}
                            <input type="text" ref={(input) => this.name = input} className={validator.name?'form-control input-error':'form-control'} placeholder="Your Name"/>
                        
                            {validator.name && <span className="help-block form-text text-danger">{validator.name}</span>}
                            
                        </div>
                        <div class="col-md-6">
                            <input type="text" ref={(input) => this.phone = input} className={validator.phone?'form-control input-error':'form-control '} placeholder="Phone Number" />
                            {validator.phone && <span className="help-block form-text text-danger">{validator.phone}</span>}
                            
                        </div>
                        <div class="col-md-6">
                            <input type="email" ref={(input) => this.email = input} className={validator.email?'form-control  input-error':'form-control '} placeholder="Email" />
                            {validator.email && <span className="help-block form-text text-danger">{validator.email}</span>}
                            
                        </div>
                        
                        
                        <div class="col-md-6">
                                    
                            <select ref={(input) => this.designation_type = input} className={validator.designation_type ? 'form-control  input-error' : 'form-control '}>
                            <option value="">Select a Department</option>
                            {designationdata.length && designationdata.map((row,key)=>{
                                
                                return(
                                        <Fragment>
                                            <option value={row.id}>{row.name}</option>

                                        </Fragment>
                                    )
                            })}
                                
                            </select>
                        </div>

                        <div className="col-md-6 ">
                            
                            <input type="file" ref={(input) => this.doc_file = input} className="form-control file_shape" placeholder="Choose File" onChange={this.onFileChange} />

                            {validator.doc_file && <span className="help-block form-text text-danger">{validator.doc_file}</span>}
                        </div>

                        <div class="col-md-6">
                                    
                            <select ref={(input) => this.position_type = input} className={validator.position_type ? 'form-control  input-error' : 'form-control '}>
                            <option value="">Select a Position</option>
                            {positiondata.length && positiondata.map((row,key)=>{
                                
                                return(
                                        <Fragment>
                                            <option value={row.id}>{row.name}</option>

                                        </Fragment>
                                    )
                            })}
                                
                            </select>
                        </div>

                    </div>
                    
                   

                    <div class="submit-btn text-center">
                        {!this.state.api_call&&<button type="button" class="btn btn-primary"  onClick={() => this.handleSubmit()}>
                            Submit
                        </button>}
                        {this.state.api_call&&<button type="button" disabled={true} class="btn btn-primary">
                            Submit
                        </button>}
                        
                    </div>


                </form>
            </Fragment>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    setPath: (requestData) => { dispatch(setPath(requestData)) },
    setLayout: (requestData) => { dispatch(setLayout(requestData)) },
    setPathLayout: (requestData) => { dispatch(setPathLayout(requestData)) },
    sendContactus: (requestData) => { dispatch(sendContactus(requestData)) },
    careerAdd: (requestData) => dispatch(careerAdd(requestData)),
    
})

export default withRouter(connect(null, mapDispatchToProps)(CareerForm));