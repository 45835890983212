import InnerHTML from 'dangerously-set-inner-html';
import React, { Fragment, useState } from 'react';
import { Link } from "react-router-dom";
import { image_url } from '../../config/Api';
const Member = ({ member, lang }) => {

    const [hidden, setHidden] = useState({});
    const toggleHide = index => {
        setHidden({ hidden, [index]: !hidden[index] });
    };
    return (
        <Fragment>
            {
                member.length > 0 ?
                    member.map((row, key) => {
                        return (
                            <Fragment key={key}>


                                <div className="col-md-4">
                                    <div className="director-single">
                                        <img src={row.image ? image_url + row.image : ''} alt="Profile" />
                                        <div className="director-single-content">

                                            {lang === 'en' && <Fragment><h5>{row.council_designation}</h5></Fragment>}
                                            {lang === 'bn' && <Fragment><h5>{row.bn_council_designation}</h5></Fragment>}


                                            {lang === 'en' && <Fragment><h3>{row.name}</h3></Fragment>}
                                            {lang === 'bn' && <Fragment><h3>{row.bn_name}</h3></Fragment>}


                                            {!hidden[key] && <div className="plus-icon Showdetails" onClick={e => toggleHide(key)}><i className="fa fa-plus"></i></div>}
                                            {!!hidden[key] && <div className="plus-icon  Hideclose" onClick={e => toggleHide(key)}><i className="fa fa-times"></i></div>}
                                        </div>
                                    </div>
                                    {!!hidden[key] && <div className="director-details">
                                        <div className="row">
                                            {/* <div className="col-md-3">
                                                <img src={row.image ? image_url + row.image : ''} alt="Profile" />
                                            </div> */}
                                            <div className="col-md-12">

                                                {lang === 'en' && <Fragment><h3>{row.name}</h3></Fragment>}
                                                {lang === 'bn' && <Fragment><h3>{row.bn_name}</h3></Fragment>}


                                                {lang === 'en' && <Fragment><h5>{row.council_designation}</h5></Fragment>}
                                                {lang === 'bn' && <Fragment><h5>{row.bn_council_designation}</h5></Fragment>}

                                                
                                                <div className="member-details-description">
                                                    
                                                    {lang === 'en' && <Fragment><InnerHTML html={row.description} /></Fragment>}
                                                    {lang === 'bn' && <Fragment><InnerHTML html={row.bn_description} /></Fragment>}
                                                </div>
                                                {row.phone && lang === 'en' && <h6><i className="fa fa-phone-alt"></i>  {row.phone}</h6>}
                                                {row.phone && lang === 'bn' && <h6><i className="fa fa-phone-alt"></i>  {row.bn_phone}</h6>}
                                                {row.email && <h6><i className="fa fa-envelope"></i>  {row.email}</h6>}
                                            </div>
                                        </div>
                                    </div>}
                                </div>
                            </Fragment>
                        )
                    })
                    :
                    ''
            }
        </Fragment>
    )
}

export default Member
