import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { image_url } from "../../config/Api";


export const WhatWeSeek = ({ data}) => {
    return (
        <Fragment>

            <div class="what-we-seek">
                <div class="container">
                    <div class="title-black text-center">
                        <h1>What We Seek</h1>
                    </div>
                    <div class="row">
                        {data && data.map((row,key)=>{
                            return(
                                <Fragment>
                                    {row.list && row.list.map((prow,key)=>{

                                        return(
                                            <Fragment>
                                                <div class="col-md-4">
                                                    <div class="flip-card">
                                                        <div class="flip-wrapper">
                                                            <div class="flip-front">
                                                                <div class="front-contents">
                                                                    <img src={image_url+''+prow.image}></img>
                                                                    <h6>{prow.title ? prow.title : ''}</h6>
                                                                </div>
                                                                <div class="flip-overlay"></div>
                                                            </div>
                                                            <div class="flip-back">
                                                                <div class="back-contents">
                                                                    <p>{prow.description ? prow.description : ''}</p>
                                                                </div>
                                                                <div class="flip-overlay2"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                

                                            </Fragment>
                                        )
                                    })}

                                </Fragment>
                            )
                        })}
                        
                        
                    </div>
                </div>
            </div>

        </Fragment>
    )
}

