import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { image_url } from "../../config/Api";


export const PreferedSectors = ({ data}) => {
    return (
        <Fragment>

            

            <div class="fund-middle-content investment-process-middle">
                <div class="container">
                    <div class="title-black text-center">
                        <h1>Preferred Sectors</h1>
                    </div>
                    <div class="fund-middle-content-block">
                        <div class="row">
                            {data && data.map((row,key)=>{
                                    return(
                                            <Fragment>
                                                {row.list && row.list.map((prow,key)=>{

                                                    return(
                                                        <Fragment>

                                                            
                                                            <div class="col-md-3">
                                                                <div class="fund-block-single">
                                                                    <div class="fund-block-icon">
                                                                        <img src={image_url+''+prow.image} alt=""></img>
                                                                    </div>
                                                                    <h4>{prow.title ? prow.title : ''}</h4>
                                                                </div>
                                                            </div>


                                                        </Fragment>
                                                    )
                                                    })}
        
                                            </Fragment>
                                        )
                                })}
                            
    
                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    )
}

