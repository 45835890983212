import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { image_url } from "../../config/Api";


export const FundDocument = ({ data}) => {
    return (
        <Fragment>

        <div class="investment-journey fund-documents accordion-styles accordion--three">
            <div class="container">
                <div class="title-black">
                    <h1>Fund Documents</h1>
                </div>
                
                <div class="accordion accordion_four" id="accordion_four">
                {data && data.map((row,key)=>{
                return(
                    <Fragment>
                        <div class="accordion-single">
                            <div class="accordion-heading" id={`accordion_four_heading${key}`}>
                            <h6 class="mb-0">
                                <a href="#"  className={` ${key == 0 ? 'bg-gray-light' : 'collapsed bg-gray-light'}`}  data-toggle="collapse" data-target={`#accordion_four_collapse${key}`}  aria-expanded={`${key == 0 ? 'true' : 'false'}`} aria-controls={`accordion_four_collapse${key}`} >
                                
                                    {row.category_name ? row.category_name : ''}
                                </a>
                            </h6>
                            </div>
                            <div id={`accordion_four_collapse${key}`}  className={` ${key == 0 ? 'collapse show' : 'collapse'}`}   aria-labelledby={`accordion_four_heading${key}`}  data-parent="#accordion_four">
                            
                                <div class="accordion-contents">
                                    {row.WebContent.length ? <Fragment>
                                        <ul>

                                        {row.WebContent.map((prow, pkey) => {
                                            return (
                                                <Fragment>

                                            
                                                    <li>
                                                        <a href={image_url+''+prow.file_path} download="" target="_blank">
                                                            <i class="fa fa-file-pdf"></i> {prow.name}
                                                        </a> 
                                                    </li>
                                                </Fragment>

                                            )
                                        })}
                                            
                                            
                                            
                                        </ul>
                                    </Fragment> : ''}
                                </div>
                            </div>
                        </div>
                
                    </Fragment>
                    )
                })}
               
                </div>
            </div>
        </div>

 
           

        </Fragment>
    )
}

