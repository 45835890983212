
import $ from 'jquery';
import Cookies from 'js-cookie';
import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { getAllNavigation, getHeaderNavigation, getTopNavigation, setLayout, setPath, setPathLayout, setSearchKey, getSetting, getHomeSliderData } from '../../../actions/Utils';
import Logo from '../../../img/logo.png';
import Logo2 from '../../../img/logo.png';
import LangData from '../LangData';
import LangSelector from './LangSelector';
import MultiMenus from './Multimenu';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import MultiMenusMobile from './MultimenuMobile';
import { base_path, image_url } from '../../../config/Api';

// import '../../../Styles/App.css'
// import '../../../Styles/Header.css'


class CommonheaderSlider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            path: '',
            header: {},
            top_menu: [],
            header_menu: [],
            megamenu_class: style.display_none,
            search: '',
            home_slider: {},
            accept_cookies: false,
            setting: {},
            menu_open: false, //menu open of off
            back_button: true, //show back button
            menu_show_row: -1, //sub menu logic
            is_home: true
        }

    }

    // componentDidMount = () => {
    //     // const script = document.createElement('script');
    //     // script.src = '/theme_assets/js/revolution.slider.init.js';
    //     // script.async = true;
    //     // script.onload = () => {
    //     //     alert("External script loaded")
    //     // }
    //     // document.body.appendChild(script);

    //     function loadScript(src) {
    //         return new Promise(function(resolve, reject){
    //           var script = document.createElement('script');
    //           script.src = src;
    //           script.addEventListener('load', function () {
    //             resolve();
    //           });
    //           script.addEventListener('error', function (e) {
    //             reject(e);
    //           });
    //           document.body.appendChild(script);
    //           document.body.removeChild(script);
    //         })
    //       };
  
    //     loadScript('/theme_assets/js/revolution.slider.init.js');



    //         var wHeight = window.innerHeight;
    //         //search bar middle alignment
    //         $('#mk-fullscreen-searchform').css('top', wHeight / 2);
    //         //reform search bar
    //         $(window).resize(function () {
    //             wHeight = window.innerHeight;
    //             $('#mk-fullscreen-searchform').css('top', wHeight / 2);
    //         });
    //         // Search
    //         $('#search-button').click(function (e) {
    //             e.preventDefault();
    //             $("div.mk-fullscreen-search-overlay").addClass("mk-fullscreen-search-overlay-show");
    //         });
    //         $("a.mk-fullscreen-close").click(function (e) {
    //             e.preventDefault();
    //             $("div.mk-fullscreen-search-overlay").removeClass("mk-fullscreen-search-overlay-show");
    //         });
       

    //     this.props.getSetting().then((res) => {
    //         this.setState({
    //             setting: res
    //         })
    //     });

    //     this.props.getHomeSliderData().then((res) => {
    //         this.setState({
    //             home_slider: res

    //         })
    //     });

    //     this.props.getHeaderNavigation({ navigation_name: 'header' }).then((res) => {
    //         this.setState({
    //             header_menu: res
    //         })

    //         // this.menuLoad();
    //     });

    //     this.props.getTopNavigation({ navigation_name: 'header toggle' }).then((res) => {
    //         this.setState({
    //             top_menu: res
    //         })

    //         // this.menuLoad();
    //     });

    //     var accept_cookiess = Cookies.get('accept_cookies');
    //     if (accept_cookiess && accept_cookiess == true) {
    //         this.setState({ accept_cookies: true });
    //     }

    //     // var login_token = Cookies.get('login_token');
    //     // if (!login_token || login_token !== localStorage.getItem('token')) {
    //     //     localStorage.removeItem("token");
    //     //     localStorage.removeItem("member_id");
    //     //     localStorage.removeItem("member_pass");
    //     // }


    // }

    // componentDidUpdate = (prevProps) => {

    //     const { location } = this.props.history;
    //     var { pathname } = location;
    //     console.log('pathnamell',pathname);

        
    //     if (base_path == '/') {
    //         if (pathname != '/' && pathname != '/home') {
                
    //             this.setState({
    //                 is_home: false
    //             })
    //         }
    //     }
    //     else {
    //         if (pathname != '/' && pathname != '/home') {
    //             this.setState({
    //                 is_home: false
    //             })
    //         }
    //     }

    


    //     // function loadScript(src) {
    //     //     return new Promise(function(resolve, reject){
    //     //       var script = document.createElement('script');
    //     //       script.src = src;
    //     //       script.addEventListener('load', function () {
    //     //         resolve();
    //     //       });
    //     //       script.addEventListener('error', function (e) {
    //     //         reject(e);
    //     //       });
    //     //       document.body.appendChild(script);
    //     //       document.body.removeChild(script);
    //     //     })
    //     //   };
  
    //     // loadScript('/theme_assets/js/revolution.slider.init.js');
      
    // }


   

    handleMenu = (status, key, total_header) => {

        if ($(window).width() <= 943) {//for mobile scene

            // console.log('samiur',total_header);

            if (key != 0 && key != total_header - 1) {

                this.setState({
                    menu_open: status,
                    menu_show_row: key,
                    back_button: true,
                })


            } else {
                //if contact or home then close menu
                this.setState({
                    menu_open: false,
                    back_button: false

                })

                $('body').removeClass('open-menu');
                $('.hamburger-menu .bar').toggleClass('animate');

            }




        } else {
            //except mobile scene
            this.setState({
                menu_open: status,
                menu_show_row: key,
                back_button: false,
            })

        }
        // console.log(key);

    }



    handleMenuRemove = () => {
        //close menu for (home,contact) or 2nd layer link

        this.setState({
            menu_open: false,
            back_button: false

        })


        $('body').removeClass('open-menu');
        $('.hamburger-menu .bar').toggleClass('animate');





    }


    acceptCookies = (e) => {
        e.preventDefault();
        Cookies.set('accept_cookies', 1);
        this.setState({ accept_cookies: true });
    }
    static getDerivedStateFromProps(props, state) {

        return {
            header: props.headers,
            lang: props.lang
        }
    }



    megaMenuShow = () => {
        // console.log("Hello Mobarak Yes hsow")
        this.setState({
            megamenu_class: style.display_block,
        })
    }
    megaMenuHide = () => {
        this.setState({
            megamenu_class: style.display_none,
        })
    }


    render() {


        const top_menu = this.props.headers.top_menu;
        
        
        const header_menu = this.props.headers.header_menu;
        //console.log("data setting state =", this.state);
        const { megamenu_class, setting, menu_open, menu_show_row, back_button, home_slider } = this.state;

        // console.log('is_home =',is_home);
        // const lang = Cookies.get('lang')?Cookies.get('lang'):'bn';
        const lang = this.props.lang;
        var search_placeholder = lang == 'bn' ? 'অনুসন্ধান' : 'look out';

        var pathname = window.location.pathname;
        var headerclassname = '';
        var flag = true;
        if (base_path == '/') {
            if (pathname != '/' && pathname != '/home') {
                headerclassname = 'header-2';
                flag = false;
            }
        }
        else {
            if (pathname != '/' && pathname != '/home') {
                headerclassname = 'header-2';
                flag = false;
            }
        }


        // console.log("pathname =",pathname);
        // console.log("Samiur =", header_menu);

        return (



            <Fragment>

                <section class="intro-area">
                    <div class="">
                        <div class="intro-area-11">

                            <section class="header header--8">

                                <div class="menu_area menu8 menu--sticky">
                                    <div class="container">
                                        <nav class="navbar navbar-expand-lg navbar-light px-0 ">
                                            <Link className='navbar-brand order-sm-1 order-1' to={{ pathname: `/` }} >
                                                <img src={Logo} alt="Logo" />
                                            </Link>

                                            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent22" aria-controls="navbarSupportedContent22" aria-expanded="false" aria-label="Toggle navigation">
                                                <span class="la la-bars"></span>
                                            </button>
                                            <div class="collapse navbar-collapse order-md-1 justify-content-end" id="navbarSupportedContent22">
                                                <ul class="navbar-nav ">

                                                    {header_menu.length ? <Fragment>
                                                        {header_menu.map((row, key) => {
                                                            return (
                                                                <Fragment>

                                                                    {row.sub_nav.length ? <Fragment>
                                                                        <li class="nav-item dropdown">
                                                                            <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{row.title}</a>

                                                                            <div class="dropdown-menu">
                                                                                {row.sub_nav.map((prow, pkey) => {
                                                                                    return (
                                                                                        <Fragment>


                                                                                            <Link className='dropdown-item' to={{ pathname: `${prow.url}` }} >{prow.title}</Link>


                                                                                        </Fragment>

                                                                                    )
                                                                                })
                                                                                }
                                                                            </div>
                                                                        </li>
                                                                    </Fragment> : <Fragment>
                                                                        <li class="nav-item ">
                                                                            <Link className='nav-link' to={{ pathname: `${row.url}` }} >{row.title}</Link>

                                                                        </li>
                                                                    </Fragment>}


                                                                </Fragment>
                                                            )
                                                        })}
                                                    </Fragment> : ''}

                                                </ul>

                                            </div>
                                            <div class="nav_right_content m-left-30 d-flex align-items-center order-2 order-sm-2">

                                                <div class="nav_right_module">
                                                    <a href="#"><i class="far fa-user"></i></a>
                                                </div>

                                            </div>
                                        </nav>
                                    </div>
                                </div>

                            </section>
                            { flag ? <Fragment>
                            <div id="rev_slider_35_1_wrapper" class="rev_slider_wrapper fullwidthbanner-container" data-alias="slider9" data-source="gallery" style={{ margin: '0px auto', background: 'transparent', padding: '0px', marginTop: '0px', marginBottom: '0px' }} >

                                <div id="rev_slider_35_1" class="rev_slider without_overlay fullwidthabanner" style={{ display: 'none' }} data-version="5.4.8.1">
                                    <ul>

                                        <li data-index="rs-72" data-transition="fade" data-slotamount="default" data-hideafterloop="0" data-hideslideonmobile="off" data-easein="default" data-easeout="default" data-masterspeed="300" data-delay="8970" data-rotate="0" data-saveperformance="off" data-title="Slide" data-param1="" data-param2="" data-param3="" data-param4="" data-param5="" data-param6="" data-param7="" data-param8="" data-param9="" data-param10="" data-description="">

                                            <img src="" data-bgcolor='' alt="" data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" data-bgparallax="off" class="rev-slidebg" data-no-retina></img>



                                            <div id="rrzm_72" class="rev_row_zone rev_row_zone_middle" style={{ zIndex: '5' }}>

                                                <div class="tp-caption  " id="slide-72-layer-1" data-x="" data-y="center" data-voffset="" data-width="['auto']" data-height="['auto']" data-type="row" data-columnbreak="3" data-responsive_offset="on" data-responsive="off" data-frames='[{"delay":10,"speed":300,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]' data-margintop="[0,0,0,0]" data-marginright="[0,0,0,0]" data-marginbottom="[0,0,0,0]" data-marginleft="[0,0,0,0]" data-textAlign="['inherit','inherit','inherit','inherit']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{ zIndex: '5', whiteSpace: 'nowrap', fontSize: '17px', lineHeight: '22px', fontWeight: '400', color: '#ffffff', letterSpacing: '0px' }}>

                                                    <div class="tp-caption  " id="slide-72-layer-2" data-x="100" data-y="100" data-width="['auto']" data-height="['auto']" data-type="column" data-responsive_offset="on" data-responsive="off" data-frames='[{"delay":"+0","speed":300,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]' data-columnwidth="50%" data-verticalalign="top" data-margintop="[0,0,0,0]" data-marginright="[0,0,0,0]" data-marginbottom="[0,0,0,0]" data-marginleft="[0,0,0,0]" data-textAlign="['inherit','inherit','inherit','inherit']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{ zIndex: '6', width: '100%' }}>

                                                        <h1 class="tp-caption   tp-resizeme" id="slide-72-layer-4" data-x="" data-y="" data-width="['auto']" data-height="['auto']" data-type="text" data-responsive_offset="on" data-fontsize="['48', '44', '40', '38']" data-lineheight="['60', '50', '48', '44']" data-frames='[{"delay":"+10","split":"chars","splitdelay":0.05,"speed":1040,"split_direction":"forward","frame":"0","from":"x:[105%];z:0;rX:45deg;rY:0deg;rZ:90deg;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","to":"o:1;","ease":"Power4.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]' data-margintop="[0,0,0,0]" data-marginright="[0,0,0,0]" data-marginbottom="[0,0,0,0]" data-marginleft="[0,0,0,0]" data-textAlign="['inherit','inherit','inherit','inherit']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{ zIndex: '7', whiteSpace: 'normal', fontSize: '48px', lineHeight: '60px', fontWeight: '600', color: '#53B78F', letterSpacing: '0px', display: 'block' }}>

                                                            <span >{home_slider.bn_title}</span><br></br>{home_slider.bn_short_descripton}

                                                        </h1>

                                                        <div class="tp-caption   tp-resizeme" id="slide-72-layer-5" data-x="" data-y="" data-width="['auto']" data-height="['auto']" data-type="text" data-responsive_offset="on" data-fontsize="['17', '17', '17', '16']" data-lineheight="['32', '32', '32', '22']" data-frames='[{"delay":"+1550","speed":1420,"sfxcolor":"#ffffff","sfx_effect":"blockfromtop","frame":"0","from":"z:0;","to":"o:1;","ease":"Power4.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]' data-margintop="[35,35,35,35]" data-marginright="[0,0,0,0]" data-marginbottom="[35,35,35,35]" data-marginleft="[0,0,0,0]" data-textAlign="['inherit','inherit','inherit','inherit']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{ zIndex: '8', whiteSpace: 'normal', fontSize: '17px', lineHeight: '32px', fontWeight: '400', color: '#202428', letterSpacing: '0px', display: 'block' }}>
                                                            {home_slider.short_descripton}
                                                        </div>

                                                        <div class="tp-caption" id="slide-72-layer-6" data-x="" data-y="" data-width="['auto']" data-height="['auto']" data-type="text" data-responsive_offset="on" data-frames='[{"delay":"+3520","speed":560,"frame":"0","from":"x:200px;skX:-85px;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]' data-margintop="[0,0,0,0]" data-marginright="[0,0,0,0]" data-marginbottom="[0,0,0,0]" data-marginleft="[0,0,0,0]" data-textAlign="['inherit','inherit','inherit','inherit']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{ zIndex: '9', display: 'inline-block' }}>

                                                            <Link to={{ pathname: `${home_slider.external_link}` }} class="btn btn-primary" >Explore More</Link>
                                                        </div>
                                                    </div>

                                                    <div class="tp-caption  " id="slide-72-layer-3" data-x="100" data-y="100" data-width="['auto']" data-height="['auto']" data-visibility="['on', 'off', 'off', 'off']" data-type="column" data-responsive_offset="on" data-responsive="off" data-frames='[{"delay":"+0","speed":300,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]' data-columnwidth="50%" data-verticalalign="top" data-margintop="[0,0,0,0]" data-marginright="[0,0,0,0]" data-marginbottom="[0,0,0,0]" data-marginleft="[0,0,0,0]" data-textAlign="['inherit','inherit','inherit','inherit']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{ zIndex: '7', width: '100%' }}></div>
                                                </div>
                                            </div>

                                            <div class="tp-caption tp-caption-banner  tp-resizeme rs-parallaxlevel-1" id="slide-72-layer-7" data-x="right" data-hoffset="-70" data-y="center" data-voffset="0" data-width="['none','none','none','none']" data-height="['none','none','none','none']" data-visibility="['on','on','on','off']" data-type="image" data-responsive_offset="on" data-frames='[{"delay":4230,"speed":1500,"frame":"0","from":"x:[175%];y:0px;z:0;rX:0;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:1;","mask":"x:[-100%];y:0;s:inherit;e:inherit;","to":"o:1;","ease":"Power3.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]' data-textAlign="['inherit','inherit','inherit','inherit']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{ zIndex: '1' }}><img src={image_url + '' + home_slider.long_image} alt="" data-ww="" data-hh="370px" data-no-retina></img></div>
                                        </li>
                                    </ul>
                                    <div class="tp-bannertimer tp-bottom" style={{ visibility: 'hidden !important' }}></div>
                                </div>
                            </div>
                            </Fragment>:''}

                        </div>
                    </div>
                </section>

            </Fragment >

        );
    }
}

const mapStateToProps = (state) => ({
    headers: state.header,
    setting: state.utils.setting,
    lang: state.utils.lang,
})

const mapDispatchToProps = (dispatch) => ({
    setPath: (requestData) => { dispatch(setPath(requestData)) },
    setLayout: (requestData) => { dispatch(setLayout(requestData)) },
    setPathLayout: (requestData) => { dispatch(setPathLayout(requestData)) },
    setSearchKey: (requestData) => { dispatch(setSearchKey(requestData)) },
    getAllNavigation: (requestData) => dispatch(getAllNavigation(requestData)),
    getHomeSliderData: (requestData) => dispatch(getHomeSliderData(requestData)),
    getTopNavigation: (requestData) => dispatch(getTopNavigation(requestData)),
    getHeaderNavigation: (requestData) => dispatch(getHeaderNavigation(requestData)),
    getSetting: (requestData) => dispatch(getSetting(requestData)),

})


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CommonheaderSlider));

const style = {
    display_none: {
        display: 'none'
    },
    display_block: {
        display: 'block'
    }
};