import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter,Link } from "react-router-dom";
import { pageSectionContent, getSetting,PageContent, setPathLayout, getProfileDetails } from "../../actions/Utils";

import Htmlcontent from '../../components/Htmlcontent';
import BreadCrumbs from '../../components/page/BreadCrumbs';
import { image_url, host_name } from '../../config/Api';
import BannerLeft from '../page/BannerLeft';


class PeopleDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            path: '',
            page_content:{},
            contents: null,
            content_load: false
        };
    }
    componentDidMount = () => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        const { params } = this.props.match;
        this.props.getProfileDetails({ slug: params.slug }).then((data) => {
            console.log("pageSectionContent =", data);
            this.setState({
                contents: data,
                content_load: true
            })
        });

        const slug = 'portfolio';

        this.props.PageContent(slug).then((res)=>{

        });

    }

    setPathLayout = (parms) => {
        this.props.setPathLayout(parms);
    }
    getSetting = async () => {
        let data = await this.props.getSetting();
    }
    static getDerivedStateFromProps(props) {
        return {
            path: props.utils.path,
            header: props.header,
            container: props.container,
            footer: props.footer,
            layout_id: props.utils.layout_id,
            lang: props.lang
        };
    }


    render() {

        // console.log("common state =", this.state)
        const { contents, content_load } = this.state;

        const {page_data}=this.props.page_content.content;


        console.log('contents=', contents);

        return (
            <Fragment>
                {/* <BannerLeft title={contents ? contents.title : ''} menu_name={''} banner={''} /> */}
            {page_data && <Fragment>
                
                {contents && contents.length ? <Fragment>
                            {contents.map((row, key) => {
                                return (

                                    
                                    <Fragment>

                                        

                                        {page_data && <Fragment>

                                        <section class="inner-header">
                                            <img src={page_data.image ? image_url + page_data.image : `${host_name}img/innerbaner.png`} alt=""></img>
                                            <h1>{row.title}</h1>
                                            <div class="inner-breadcrumb">
                                                <ul class="nav justify-content-center">
                                                    <li><Link to={{ pathname: `/home` }} >Home</Link></li>
                                                    
                                                    <li><Link to={{ pathname: `${page_data.slug}` }} >{page_data.title}</Link></li>
                                                    <li>{row.title}</li>
                                                </ul>
                                            </div>
                                            <div class="overlay"></div>
                                        </section>

                                        </Fragment> }
                                        

                                        <section class="sponsor">
                                            <div class="container">
                                                <div class="row">
                                                    <div class="col-md-8">
                                                        <div class="sponsor-left mb-5">
                                                        {row.slider_description &&   <Htmlcontent data={row.slider_description} /> }
                                                            
                                                            
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="sponsor-right mb-5">
                                                            
                                                            {row.image &&   <img src={`${image_url}${row.image}`} alt="" /> }

                                                            {row.portfolio_ceo && <Fragment>
                                                            <h3>CEO </h3>
                                                            <p>
                                                                {row.portfolio_ceo}
                                                            </p>
                                                            </Fragment>}
                                                            {row.portfolio_inception && <Fragment>
                                                            <h3>Inception</h3>
                                                            <p>{row.portfolio_inception}</p>
                                                            </Fragment>}

                                                            {row.category_name && <Fragment>
                                                            <h3>Industry</h3>
                                                            <p>{row.category_name}</p>
                                                            </Fragment>}

                                                            {row.portfolio_status_name ? <Fragment>
                                                            <h3>Status</h3>
                                                            <p>{row.portfolio_status_name}</p>
                                                            </Fragment>:<Fragment>
                                                            <h3>Status</h3>
                                                            <p>Active</p>
                                                            </Fragment>}
                                                            
                                                            
                                                            
                                                            
                                                            <div class="sponsor-social">

                                                            {row.bn_short_description && <a target='_blank' href={row.bn_short_description}><i className="fab fa-facebook-f"></i>  
                                                                        
                                                            </a>}

                                                            {row.short_description && <a target='_blank' href={row.short_description}><i className="fal fa-globe"></i> 
                                                                        
                                                            </a>}

                                                            {row.bn_title && <a target='_blank' href={row.bn_title}><i className="fab fa-linkedin-in"></i> 
                                                                       
                                                            </a>}
                                                            
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    
                                    </Fragment>

                                )
                        })}
                </Fragment> : ''}

            </Fragment> }
            </Fragment >
        );
    }
}

const mapStateToProps = state => ({
    page_content:state.contents.page_content,
    utils: state.utils,
    layout_id: state.utils.layout_id,
    setting: state.utils.setting,
    lang: state.utils.lang

})

const mapDispatchToProps = dispatch => ({
    getSetting: () => dispatch(getSetting()),
    setPathLayout: (requestData) => dispatch(setPathLayout(requestData)),
    PageContent: (requestData) => dispatch(PageContent(requestData)),
    pageSectionContent: (requestData) => dispatch(pageSectionContent(requestData)),
    getProfileDetails: (requestData) => dispatch(getProfileDetails(requestData)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PeopleDetails));