import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
export default ({ bread_curmbs }) => {

  const { i18n } = useTranslation();

  return (
    <section className="breadcrumb">

      <div className="container">

        <ul className="nav">
          {
            bread_curmbs.slice(0).reverse().map((row, key) => {
              return <li key={key}>
                {
                  bread_curmbs.length - 1 != key ? <Fragment>
                    {row.url &&<Link to={`${process.env.PUBLIC_URL}` + row.url} >
                      {i18n.resolvedLanguage === 'en' && <Fragment>{row.title}</Fragment>}
                      {i18n.resolvedLanguage === 'bn' && <Fragment>{row.bn_title} </Fragment>}
                    </Link>}
                    {!row.url &&<Fragment>
                      {i18n.resolvedLanguage === 'en' && <Fragment>{row.title}</Fragment>}
                      {i18n.resolvedLanguage === 'bn' && <Fragment>{row.bn_title} </Fragment>}
                    </Fragment>}
                    
                  </Fragment>

                    :
                    <Fragment>
                      {i18n.resolvedLanguage === 'en' && <Fragment>{row.title}</Fragment>}
                      {i18n.resolvedLanguage === 'bn' && <Fragment>{row.bn_title}</Fragment>}
                    </Fragment>
                }
              </li>
            })
          }

        </ul>

      </div>

    </section >
  )
}
