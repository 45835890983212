import $ from 'jquery';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from "react-router-dom";
import { pageSectionContent,PageContent, getSetting, setPathLayout, getPortfolio, getIndustry,getPortfolioStatus} from "../../actions/Utils";

import Htmlcontent from '../../components/Htmlcontent';
import BreadCrumbs from '../../components/page/BreadCrumbs';
import { image_url, host_name } from '../../config/Api';
import BannerLeft from '../page/BannerLeft';
import {ProfileItem} from './ProfileItem';


class Portfolio extends Component {
    constructor(props) {
        super(props);
        this.state = {
            path: '',
            contents: [],
            content_load: false,
            contents_data: {},
            page_content:{},
            portfolio_data:{},
            filterdata:{},
            industry_data:{},
            portfolio_status:{},
        };
    }
    componentDidMount = () => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        const { path } = this.props.match;

        const slug = this.props.match.path.replace('/','');

        this.props.PageContent(slug).then((res)=>{

        });

        
        

        this.props.getPortfolio().then((res)=>{
            this.setState({
                portfolio_data:res,
                filterdata:res
              })

              if(res.length > 0){
                this.setState({
                    content_load:true
                    
                  })
              }
   
        });

        this.props.getIndustry().then((res)=>{
            this.setState({
                industry_data:res
              })
        });

        this.props.getPortfolioStatus().then((res)=>{
            this.setState({
                portfolio_status:res
              })
        });

        
        

    }

    

    componentDidUpdate = () => {
        // console.log('here i am');

        window.scroll({ top: 0, left: 0, behavior: 'smooth' });

        function loadScript(src) {
            return new Promise(function(resolve, reject){
              var script = document.createElement('script');
              script.src = src;
              script.addEventListener('load', function () {
                resolve();
              });
              script.addEventListener('error', function (e) {
                reject(e);
              });
              document.body.appendChild(script);
              document.body.removeChild(script);
            })
          };
  
        loadScript('/theme_assets/js/tab.js');
      
    }

    setPathLayout = (parms) => {
        this.props.setPathLayout(parms);
    }
    getSetting = async () => {
        let data = await this.props.getSetting();
    }
    static getDerivedStateFromProps(props) {
        return {
            path: props.utils.path,
            header: props.header,
            container: props.container,
            
            footer: props.footer,
            layout_id: props.utils.layout_id,
            lang: props.lang

        };
    }

    ChangeContent = () => {
        // console.log('Content Change');

        const { portfolio_data } = this.state;
        let filterdata_temp;

        // var status = $("#status").val();
        var industry = $("#industry").val();

        var status = $('a.nav-link.active').attr('value');
        
        console.log('status = ',status);
        console.log('portfolio_data = ',portfolio_data);

        if(status==0){

            // filterdata = portfolio_data;
            if(industry==0){

                filterdata_temp = portfolio_data;

            }else{

                filterdata_temp = portfolio_data.filter(obj => obj.inc_category_id == industry)

            }
            

        }else{

           

            if(industry==0){

                // filterdata = portfolio_data;
                filterdata_temp = portfolio_data.filter(obj => obj.portfolio_status_id == status)
    
            }else{
    
                filterdata_temp = portfolio_data.filter(obj => obj.inc_category_id == industry && obj.portfolio_status_id == status)
    
            }

        }

        

        this.setState({
            
            filterdata:filterdata_temp
        })

       

        // console.log('filterdata data = ',filterdata_temp);
        

    }

    ChangeContentStatus = (status) => {

        const { portfolio_data } = this.state;
        let filterdata_temp;

        // var status = $("#status").val();
        var industry = $("#industry").val();

        var status = status;
        
        console.log('status = ',status);
        console.log('portfolio_data = ',portfolio_data);

        if(status==0){

            // filterdata = portfolio_data;
            if(industry==0){

                filterdata_temp = portfolio_data;

            }else{

                filterdata_temp = portfolio_data.filter(obj => obj.inc_category_id == industry)

            }
            

        }else{

           

            if(industry==0){

                // filterdata = portfolio_data;
                filterdata_temp = portfolio_data.filter(obj => obj.portfolio_status_id == status)
    
            }else{
    
                filterdata_temp = portfolio_data.filter(obj => obj.inc_category_id == industry && obj.portfolio_status_id == status)
    
            }

        }

        

        this.setState({
            
            filterdata:filterdata_temp
        })

       

        // console.log('filterdata data = ',filterdata_temp);

        
        
    }


    render() {

        const { portfolio_data,industry_data,filterdata,portfolio_status,content_load } = this.state;
        const {page_data}=this.props.page_content.content;
        

        // console.log("filterdata main =", filterdata);
        // console.log("industry_data =", industry_data);


        return (
            <Fragment>

            {page_data && <Fragment>

            {page_data && <Fragment>

            <section class="inner-header">
                <img src={page_data.image ? image_url + page_data.image : `${host_name}img/innerbaner.png`} alt=""></img>
                <h1>{page_data.title}</h1>
                <div class="inner-breadcrumb">
                    <ul class="nav justify-content-center">
                        <li><Link to={{ pathname: `/home` }} >Home</Link></li>
                        <li>{page_data.title} </li>
                    </ul>
                </div>
                <div class="overlay"></div>
            </section>

            </Fragment> }

                <div  class="tab tab--7 mb-5 people portfolio_tab">
                
                    <div class="tab_nav7 mb-5">
                        <ul class="nav nav-tabs">
                            <li class="nav-item">
                            <a class="nav-link active" value={0} onClick={() => this.ChangeContentStatus(0)} href="">All</a>
                            </li>
                            {portfolio_status.length && portfolio_status.map((row,key)=>{
                                
                                return(
                                        <Fragment>
                                           <li class="nav-item">
                                                <a class="nav-link" value={row.id} onClick={() => this.ChangeContentStatus(row.id)} href="">{row.name}</a>
                                            </li>

                                        </Fragment>
                                    )
                            })}
                            
                            <select name='industry' id='industry' onChange={() => this.ChangeContent()}>
                                <option value={0}>All Industry</option>
                                
                                {industry_data.length && industry_data.map((row,key)=>{
                                
                                    return(
                                            <Fragment>
                                                <option value={row.id}>{row.name}</option>

                                            </Fragment>
                                        )
                                })}
                            </select>
                        
                        </ul>
                    </div>

                    
                    
                
                
                    <div class="tab-content">

                        <div class="tab-pane active in" >
                            <section class="corporate-sponsor">
                                <div class="corporate-sponsor-bg">
                                    <div class="container">
                                        <div class="corporate-sponsor-content">
                                            <div class="row">
                                                
                                            { content_load ? <Fragment>
                                            
                                            { filterdata.length > 0 ?
                                                filterdata.map((row,key) => { 
                                                    return(  
                                                        

                                                        <Fragment>

                                                            <div class="col-md-4">
                                                                <div class="corporate-sponsor-single">
                                                                    <Link to={{pathname:`/portfolio/${row.slug}`}} ><img src={image_url+''+row.image} alt=""></img></Link>
                                                                
                                                                </div>
                                                            </div>

        
                                                        </Fragment>
                                                            

                                                        )
                                                        
                                                    })
                                                    :<h4 className='portfolio_empty pt-5 pb-5 mb-0 m-auto d-block'>We are yet to make an exit from this industry</h4>
                                                
                                            }

                                        </Fragment>
                                            
                                        :''}
                                                
                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>  
                        </div>
                
                    </div>
                </div>

            </Fragment> }

            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    page_content:state.contents.page_content,
    utils: state.utils,
    layout_id: state.utils.layout_id,
    setting: state.utils.setting,
    lang: state.utils.lang

})

const mapDispatchToProps = dispatch => ({
    getSetting: () => dispatch(getSetting()),
    setPathLayout: (requestData) => dispatch(setPathLayout(requestData)),
    PageContent: (requestData) => dispatch(PageContent(requestData)),
    pageSectionContent: (requestData) => dispatch(pageSectionContent(requestData)),
    getPortfolio: (requestData) => dispatch(getPortfolio(requestData)),
    getIndustry: (requestData) => dispatch(getIndustry(requestData)),
    getPortfolioStatus: (requestData) => dispatch(getPortfolioStatus(requestData)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Portfolio));