import React, { Fragment } from 'react';
import { image_url,host_name } from '../../config/Api';



export default   ({title,short_description,menu_name,banner})=> {
    return (
        <Fragment>
            

            <section className="innerpage-header">

                   
                    <img src={banner ? image_url+banner :  <img src={`${host_name}img/default-innerpage-header.png`} />}  alt="Banner"/>


                    <div className="container">

                        <div className="innerpage-header-content">


                            {title && <h3>{title}</h3>}

                            <h1>LIC</h1>

                        </div>

                    </div>

                </section>
        </Fragment>
    )
}
