import $ from 'jquery';
import qs from 'qs';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
     setLayout, PageContent,setPath, setPathLayout,
  
    investmentAdd,getStageAndIndustry
   
} from '../../actions/Utils';
import axiosInstance, { version } from '../../config/Api';

import DatePicker from "react-datepicker";
import { DateInput } from '../contactus/Dateinput';
import "react-datepicker/dist/react-datepicker.css";
import Tabs, { Tab } from 'react-best-tabs';
import 'react-best-tabs/dist/index.css';
import { withRouter,Link } from 'react-router-dom';
import LangData from '../common/LangData'
import Cookies from 'js-cookie'
import axios from 'axios';
import { image_url, host_name } from '../../config/Api';

class ApplyInvestmentForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            validator: {
                company_name: '',
                industry_name: '',
                inception_date: '',
                is_incorporated: '',
                currency_type: '',
                stage: '',
                number_of_funder: '',
                team_size: '',
                investment_till_date: '',
                incorporation_date: '',
                monthly_revenue: '',
                monthly_burn: '',
                contact_name: '',
                contact_designation: '',
                contact_email: '',
                contact_phone: '',
                contact_linkedin: '',
                contact_website: '',
                
                pitch_deck: '',
                doc_file: '',
                product_description: '',
                investment_requirement: '',
            },
            message: '',
            message_type: '',
            contents_data: {},
            page_content:{},
            formdata: {
                is_incorporated: 0,
                currency_type: 1,
                doc_file: null,
                pitch_deck:null
            },
            api_call:false

        };
        this.refs = React.createRef();

        this.handleDateChange = this.handleDateChange.bind(this);
    }

    handleMessageClose = () => {
        this.setState({ message: '', message_type: '' })
    }

    handleDateChange(date) {
        this.setState({
            startDate: date
        })
    }

    onFileChange = event => {

        // Update the state
        let doc_file = { doc_file: event.target.files[0] };
        let state_formdata = this.state.formdata;
        this.setState({ formdata: { ...state_formdata, ...doc_file } });

    };

    onFileChangePitchDeck= event => {

        // Update the state
        let pitch_deck = { pitch_deck: event.target.files[0] };
        let state_formdata = this.state.formdata;
        this.setState({ formdata: { ...state_formdata, ...pitch_deck } });

    };

    componentDidMount = () => {

        window.scroll({ top: 0, left: 0, behavior: 'smooth' });

        const { path } = this.props.match;

        const slug = this.props.match.path.replace('/','');

        this.props.PageContent(slug).then((res)=>{

        });

        this.props.getStageAndIndustry({ 'slug': path }).then((data) => {
            console.log("getStageAndIndustry =", data);
            this.setState({
              contents_data: data
              
              
            })
          });

    }



    handleSubmit = () => {

        const { formdata, validator } = this.state;

        let formUserData = new FormData();

        formUserData.append('inception_date', $("#inception_date").val());
        // formUserData.append('investment_till_date', $("#investment_till_date").val());
        formUserData.append('incorporation_date', $("#incorporation_date").val());
        formUserData.append('company_name', this.company_name.value);
        formUserData.append('investment_till_date', this.investment_till_date.value);
        formUserData.append('address', this.address.value);
        formUserData.append('industry_name', this.industry_name.value);
        formUserData.append('product_description', this.product_description.value);
        formUserData.append('stage', this.stage.value);
        formUserData.append('number_of_funder', this.number_of_funder.value);
        formUserData.append('team_size', this.team_size.value);
        formUserData.append('investment_requirement', this.investment_requirement.value);
        formUserData.append('monthly_revenue', this.monthly_revenue.value);
        formUserData.append('monthly_burn', this.monthly_burn.value);
        formUserData.append('contact_name', this.contact_name.value);
        formUserData.append('contact_designation', this.contact_designation.value);
        formUserData.append('contact_email', this.contact_email.value);
        formUserData.append('contact_phone', this.contact_phone.value);
        formUserData.append('contact_linkedin', this.contact_linkedin.value);
        formUserData.append('contact_website', this.contact_website.value);
        formUserData.append('pitch_deck', this.pitch_deck.value);
        formUserData.append('is_incorporated', formdata.is_incorporated);
        formUserData.append('currency_type', formdata.currency_type);

        if (this.state.formdata.doc_file) {
            formUserData.append(
                "doc_file",
                this.state.formdata.doc_file,
                this.state.formdata.doc_file.name
            );
        }

        if (this.state.formdata.pitch_deck) {
            formUserData.append(
                "pitch_deck",
                this.state.formdata.pitch_deck,
                this.state.formdata.pitch_deck.name
            );
        }



        // this.handlePayOnline(userFormData);


        const userFormData = {
            inception_date: $("#inception_date").val(),
            // investment_till_date: $("#investment_till_date").val(),
            incorporation_date: $("#incorporation_date").val(),
            company_name: this.company_name.value,
            investment_till_date: this.investment_till_date.value,
            address: this.address.value,
            
            industry_name: this.industry_name.value,
            product_description: this.product_description.value,
            stage: this.stage.value,
            number_of_funder: this.number_of_funder.value,
            team_size: this.team_size.value,
            investment_requirement: this.investment_requirement.value,
            monthly_revenue: this.monthly_revenue.value,
            monthly_burn: this.monthly_burn.value,
            contact_name: this.contact_name.value,
            contact_designation: this.contact_designation.value,
            contact_email: this.contact_email.value,
            contact_phone: this.contact_phone.value,
            contact_linkedin: this.contact_linkedin.value,
            contact_website: this.contact_website.value,
            pitch_deck: this.pitch_deck.value,
            doc_file: this.doc_file.value,
            pitch_deck: this.pitch_deck.value,
            is_incorporated: formdata.is_incorporated,
            currency_type: formdata.currency_type,
        }

        // return false;
        const resetValidator = {
            company_name: '',
            address: '',
            industry_name: '',
            inception_date: '',
            is_incorporated: 0,
            currency_type: 1,
            product_description: '',
            stage: '',
            number_of_funder: '',
            team_size: '',
            investment_till_date: '',
            incorporation_date: '',
            investment_requirement: '',
            monthly_revenue: '',
            monthly_burn: '',
            contact_name: '',
            contact_designation: '',
            contact_email: '',
            contact_phone: '',
            contact_linkedin: '',
            contact_website: '',
            pitch_deck: '',
            doc_file: '',
        }
        var validation = {};

        if (userFormData.company_name == '') {
            validation.company_name = 'This field is required.';
        }

        if (userFormData.address == '') {
            validation.address = 'This field is required.';
        }

        if (userFormData.industry_name == '') {
            validation.industry_name = 'This field is required.';
        }

        if (userFormData.inception_date == '') {
            validation.inception_date = 'This field is required.';
        }

        if (userFormData.product_description == '') {
            validation.product_description = 'This field is required.';
        }
        if (userFormData.stage == '') {
            validation.stage = 'This field is required.';
        }
        if (userFormData.number_of_funder == '') {
            validation.number_of_funder = 'This field is required.';
        }
        if (userFormData.team_size == '') {
            validation.team_size = 'This field is required.';
        }
        if (userFormData.investment_till_date == '') {
            validation.investment_till_date = 'This field is required.';
        }
        if (userFormData.investment_requirement == '') {
            validation.investment_requirement = 'This field is required.';
        }
        if (userFormData.monthly_revenue == '') {
            validation.monthly_revenue = 'This field is required.';
        }
        if (userFormData.monthly_burn == '') {
            validation.monthly_burn = 'This field is required.';
        }
        if (userFormData.contact_name == '') {
            validation.contact_name = 'This field is required.';
        }
        if (userFormData.contact_designation == '') {
            validation.contact_designation = 'This field is required.';
        }
        if (userFormData.contact_email == '') {
            validation.contact_email = 'This field is required.';
        }
        if (userFormData.contact_phone == '') {
            validation.contact_phone = 'This field is required.';
        }


        // if (userFormData.contact_linkedin == '') {
        //     validation.contact_linkedin = 'This field is required.';
        // }
        // if (userFormData.pitch_deck == '') {
        //     validation.pitch_deck = 'This field is required.';
        // }
        // if (userFormData.doc_file == '') {
        //     validation.doc_file = 'This field is required.';
        // }

        if (Object.keys(validation).length) {
            this.setState({ validator: { ...resetValidator, ...validation }, message: '' });
        }
        else {
            var error_validate = {};
            this.setState({api_call:true});
            this.props.investmentAdd(formUserData).then((res) => {

                // $("#dateofbirth").val('');

                if (res.status == 'success') {
                    $('#formid')[0].reset();

                    this.setState({
                        message: res.message, message_type: 'success', validator: {
                            ...resetValidator
                        },
                        formdata: {
                            is_incorporated: 0,
                            
                            currency_type: 1,
                            doc_file: null,
                            pitch_deck: null
                        },
                        api_call:false
                    })
                }
                else {
                    this.setState({ message: res.message, message_type: 'danger', validator: { ...resetValidator },api_call:false })
                }
            })
                .catch(error => {
                    console.log("Hello Policy form =", error);
                    if (error.response) {
                        var html = '';
                        if (Object.keys(error.response.data).length) {
                            for (const [key, value] of Object.entries(error.response.data.errors)) {
                                console.log(`${key} ${value}`); // "a 5", "b 7", "c 9"
                                html += value + '</br>';
                                if (typeof validator[key] != "undefined") {
                                    // error_validate[key] = `${value}`;
                                    error_validate[`${key}`] = `${value}`;
                                    // Object.assign(error_validate, {key: value});
                                }

                            }

                        }
                        // console.log(' error.response.data ',error.response.data);
                        // console.log(' error.response.status ',error.response.status);
                        // console.log('error.response.headers ',error.response.headers);
                    }

                    console.log("error_validate =", error_validate);
                    console.log("validator =", validator);
                    this.setState({ validator: { ...validation, ...error_validate },api_call:false })
                })
        }
    }






    handleRadio = (name, value) => {
        console.log('name =', name, 'value =', value);
        var question = {};
        if (name == 'is_incorporated') {
            question = { is_incorporated: value };
        }

        else {
            question = {};
        }

        this.setState({
            formdata: {
                ...this.state.formdata,
                ...question
            }
        })
    }

    handleCurrency = (name, value) => {
        
        var currency = {};
        if (name == 'currency') {
            currency = { currency_type: value };
        }

        else {
            currency = {};
        }

        this.setState({
            formdata: {
                ...this.state.formdata,
                ...currency
            }
        })
    }




    dateFormate = (date) => {

        var today = new Date(date);
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        return today = yyyy + '/' + mm + '/' + dd;

    }



    render() {

        // const contents = this.props.data.content;
        const { validator, formdata,contents_data } = this.state;

        // const {page_data}=this.props.page_content.content;
        const {page_data}=this.props.page_content.content;

        
        console.log('page_data = ',page_data);
        // console.log('stagetype = ',stagetype);

        // console.log("validator", validator)
        // console.log("formdata", formdata)
        const lang = this.props.lang;

        return (
            <Fragment>

                {page_data && <Fragment>

                {page_data && <Fragment>

                <section class="inner-header">
                    <img src={page_data.image ? image_url + page_data.image : `${host_name}img/innerbaner.png`} alt=""></img>
                    <h1>{page_data.title}</h1>
                    <div class="inner-breadcrumb">
                        <ul class="nav justify-content-center">
                            <li><Link to={{ pathname: `/home` }} >Home</Link></li>
                            <li>{page_data.title} </li>
                        </ul>
                    </div>
                    <div class="overlay"></div>
                </section>

                </Fragment> }

                

                

                <section class="apply_for_investment mb-5">
                    <div class="container">
                        {(this.state.message != '' && this.state.message_type == 'success') && <div className="col-sm-12" style={{ marginTop: '10px' }}>
                            <div className="alert  alert-success alert-dismissible fade show" role="alert">
                                {this.state.message}
                                <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => this.handleMessageClose()}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        </div>}
                        {(this.state.message != '' && this.state.message_type == 'danger') && <div className="col-sm-12" style={{ marginTop: '10px' }}>
                            <div className="alert  alert-danger alert-dismissible fade show" role="alert">
                                {this.state.message}
                                <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => this.handleMessageClose()}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        </div>}
                        <form action="#" id="formid" enctype="multipart/form-data">
                            <div class="form-group">
                                <h3>Company</h3>
                                <div class="row">
                                    <div class="col-md-6">
                                        
                                        <label>Startup Name <span>*</span></label>
                                        <input type="text" ref={(input) => this.company_name = input} className={validator.company_name ? 'form-control from-style input-error' : 'form-control from-style'} placeholder="Name Of Company" />

                                        {validator.company_name && <span className="help-block form-text text-danger">{validator.company_name}</span>}
                                    </div>
                                    <div class="col-md-6">
                                       
                                        <label>Industry</label>

                                        <select ref={(input) => this.industry_name = input} className={validator.industry_name ? 'form-control from-style input-error' : 'form-control from-style'}>
                                        <option value="">Select an Industry</option>
                                        {Object.keys(contents_data).length ?<Fragment>
                                       

                                            {contents_data.industrytype && contents_data.industrytype.map((row,key)=>{
                                                return(
                                                <Fragment>

                                                    <option value={row.id}>{row.name}</option>

                                                </Fragment>
                                                )
                                            })}
                                        
                    
                                        </Fragment>:''}
                                        
                                        </select>
                                        {validator.industry_name && <span className="help-block form-text text-danger">{validator.industry_name}</span>}
                                    </div>
                                    <div class="col-md-6">
                                        
                                        <label>Inception Date<span>*</span></label>

                                        <DateInput
                                            id="inception_date"
                                        />

                                        {validator.inception_date && <span className="help-block form-text text-danger">{validator.inception_date}</span>}
                                    </div>
                                    <div class="col-md-3">
                                        
                                        <label>Is it Incorporated?</label>

                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" id="inlineRadio1" type="radio" ref={(input) => this.is_incorporated = input} value="1" checked={formdata.is_incorporated === 1 ? true : false} onChange={() => this.handleRadio('is_incorporated', 1)}  />
                                            <label class="form-check-label" for="inlineRadio1">Yes</label>

                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" id="inlineRadio2" type="radio" ref={(input) => this.is_incorporated = input} value="0" checked={formdata.is_incorporated === 0 ? true : false} onChange={() => this.handleRadio('is_incorporated', 0)} />
                                            <label class="form-check-label" >No</label>
                                        </div>
                                    </div>
                                    
                                    {formdata.is_incorporated === 1 ? <div class="col-md-3">
                                        
                                        <label> Incorporation Date</label>
                                        <DateInput
                                            id="incorporation_date"
                                        />

                                       
                                    </div> : ''}
                                    <div class="col-md-12">
                                       
                                        <label>Address</label>
                                        <textarea className={validator.address?'form-control  input-error':'form-control'}  ref={(input) => this.address = input} placeholder="Enter Your Address" rows="6"></textarea>
                            
                                        {validator.address && <span className="help-block form-text text-danger">{validator.address}</span>}
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <h3>Product</h3>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="row">
                                            <div class="col-md-3">
                                                <label for="">Product description</label>
                                            </div>
                                            <div class="col-md-9">
                                                
                                               
                                                <textarea className={validator.product_description?'form-control  input-error':'form-control'}  ref={(input) => this.product_description = input} placeholder="Enter Prouduct Description" rows="6"></textarea>
                                    
                                                {validator.product_description && <span className="help-block form-text text-danger">{validator.product_description}</span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="row">
                                            <div class="col-md-3">
                                                <label for="">Stage</label>
                                            </div>
                                            <div class="col-md-9">
                                                
                                                

                                                <select ref={(input) => this.stage = input} className={validator.stage ? 'form-control from-style input-error' : 'form-control from-style'}>
                                                <option value="">Select a Stage</option>

                                                {Object.keys(contents_data).length ?<Fragment>
                                       

                                                    {contents_data.stagetype && contents_data.stagetype.map((row,key)=>{
                                                        return(
                                                        <Fragment>

                                                            <option value={row.id}>{row.name}</option>

                                                        </Fragment>
                                                        )
                                                    })}
                                                
                            
                                                </Fragment>:''}
                                                
                                                </select>
                                                {validator.stage && <span className="help-block form-text text-danger">{validator.stage}</span>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
            
                            <div class="form-group">
                                <h3>Operation</h3>
                                <div class="row">
                                    <div class="col-md-6">
                                        
                                        <label> Number Of Funders</label>
                                        <input type="number" ref={(input) => this.number_of_funder = input} className="form-control" placeholder="Number Of Funders" />

                                        {validator.number_of_funder && <span className="help-block form-text text-danger">{validator.number_of_funder}</span>}
                                    </div>
                                    <div class="col-md-6">
                                        
                                        <label> Team Size</label>
                                        <input type="number" ref={(input) => this.team_size = input} className="form-control" placeholder="Enter Team Size" />

                                        {validator.team_size && <span className="help-block form-text text-danger">{validator.team_size}</span>}
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <h3>Financials</h3>

                                <div class="currency d-block">
                                    <div class=" float-right">
                                        <div class="radio_container">
                                            
                                            <input class="form-check-input" id="bdt" type="radio" ref={(input) => this.currency_type = input} value="1" checked={formdata.currency_type === 1 ? true : false} onChange={() => this.handleCurrency('currency', 1)}  />
                                            <label  for="bdt">BDT</label>
                                            
                                            <input class="form-check-input" id="usd" type="radio" ref={(input) => this.currency_type = input} value="2" checked={formdata.currency_type === 2 ? true : false} onChange={() => this.handleCurrency('currency', 2)}  />
                                            <label  for="usd">USD</label>
                                        </div>
                                        
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                                
                                <div class="currency d-block">
                                    {/* <div class=" float-right">
                                        <div class="btn-group btn-toggle"> 
                                            <button className={`btn btn-xs ${formdata.currency_type === 1 ? ' btn-success active' : ' btn-default'}`} ref={(input) => this.currency_type = input} value="1" onChange={() => this.handleCurrency('currency', 1)} >BDT</button>
                                            
                                            <button className={`btn btn-xs ${formdata.currency_type === 2 ? ' btn-success active' : ' btn-default'}`} ref={(input) => this.currency_type = input} value="2" onChange={() => this.handleCurrency('currency', 2)} >USD</button>
                                        </div>
                                        
                                    </div>
                                    <div class="clearfix"></div> */}
                                        

                                        
                                </div>
                            
                                <div class="row">
                                    <div class="col-md-6">

                                        <label> Investment Till Date</label>
                                        <input type="number" ref={(input) => this.investment_till_date = input} className="form-control" placeholder="Enter Amount" />

                                        {validator.investment_till_date && <span className="help-block form-text text-danger">{validator.investment_till_date}</span>}
                                        
                                        
                                    </div>
                                    <div class="col-md-6">
                                        
                                        <label> Investment Requirement</label>
                                        <input type="number" ref={(input) => this.investment_requirement = input} className="form-control" placeholder="Enter Amount" />

                                        {validator.investment_requirement && <span className="help-block form-text text-danger">{validator.investment_requirement}</span>}
                                    </div>
                                    <div class="col-md-6">
                                        
                                        <label> Monthly Revenue</label>
                                        <input type="number" ref={(input) => this.monthly_revenue = input} className="form-control" placeholder="Enter Amount" />

                                        {validator.monthly_revenue && <span className="help-block form-text text-danger">{validator.monthly_revenue}</span>}
                                    </div>
                                    <div class="col-md-6">
                                        
                                        <label> Monthly Burn</label>
                                        <input type="number" ref={(input) => this.monthly_burn = input} className="form-control" placeholder="Enter Amount" />

                                        {validator.monthly_burn && <span className="help-block form-text text-danger">{validator.monthly_burn}</span>}
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <h3>Contact Information</h3>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label>Name</label>
                                        
                                        <input type="text" ref={(input) => this.contact_name = input} className="form-control" placeholder="Enter Your Name" />

                                        {validator.contact_name && <span className="help-block form-text text-danger">{validator.contact_name}</span>}
                                    </div>
                                    <div class="col-md-6">
                                        
                                        <label> Designation</label>
                                        <input type="text" ref={(input) => this.contact_designation = input} className="form-control" placeholder="Enter Designation" />

                                        {validator.contact_designation && <span className="help-block form-text text-danger">{validator.contact_designation}</span>}
                                    </div>
                                    <div class="col-md-6">
                                       

                                        <label>Email</label>
                                        <input type="email" ref={(input) => this.contact_email = input} className="form-control" placeholder="Enter Email number" />

                                        {validator.contact_email && <span className="help-block form-text text-danger">{validator.contact_email}</span>}
                                    </div>
                                    <div class="col-md-6">
                                        
                                        <label>Phone Number</label>
                                        <input type="text" ref={(input) => this.contact_phone = input} className="form-control" placeholder="Enter Phone number" />

                                        {validator.contact_phone && <span className="help-block form-text text-danger">{validator.contact_phone}</span>}
                                    </div>
                                    <div class="col-md-6">
                                        
                                        <label>LinkedIn</label>
                                        <input type="text" ref={(input) => this.contact_linkedin = input} className="form-control" placeholder="Enter Linkedin URL" />

                                        {validator.contact_linkedin && <span className="help-block form-text text-danger">{validator.contact_linkedin}</span>}
                                    </div>
                                    <div class="col-md-6">
                                        
                                        <label>Website</label>
                                        <input type="text" ref={(input) => this.contact_website = input} className="form-control" placeholder="Enter Website URL" />

                                        {validator.contact_website && <span className="help-block form-text text-danger">{validator.contact_website}</span>}
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <h3>Documents</h3>
                                <div class="row">
                                    <div class="col-md-6">
                                    <label>Pitch-Deck <span style={{ fontSize: '12px',textTransform: 'lowercase' }} >(pdf,ppt,doc)</span></label>
                                    <input type="file" ref={(input) => this.pitch_deck = input} className="form-control file_shape" placeholder="" onChange={this.onFileChangePitchDeck} />

                                    {validator.pitch_deck && <span className="help-block form-text text-danger">{validator.pitch_deck}</span>}
                                        
                                        
                                    </div>

                                    <div class="col-md-6 ">
                                    <label>Additional Documents <span style={{ fontSize: '12px',textTransform: 'lowercase' }} >(pdf,ppt,doc,xls)</span></label>
                                    <input type="file" ref={(input) => this.doc_file = input} className="form-control file_shape" placeholder="" onChange={this.onFileChange} />

                                    {validator.doc_file && <span className="help-block form-text text-danger">{validator.doc_file}</span>}
                                    </div>
                                </div>
                            </div>
                            
                            
                            <div class="submit-btn text-center mt-3">
                                {!this.state.api_call&&<button type="button" class="btn btn-primary"  onClick={() => this.handleSubmit()}>
                                    Submit
                                </button>}
                                {this.state.api_call&&<button type="button" disabled={true} class="btn btn-primary">
                                    Submit
                                </button>}
                                
                            </div>
                            
                        </form>

                        
                    </div>
                </section>

                </Fragment> }
                
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    page_content:state.contents.page_content
    
    
})

const mapDispatchToProps = (dispatch) => ({
    setPath: (requestData) => { dispatch(setPath(requestData)) },
    setLayout: (requestData) => { dispatch(setLayout(requestData)) },
    PageContent: (requestData) => dispatch(PageContent(requestData)),
    setPathLayout: (requestData) => { dispatch(setPathLayout(requestData)) },
    investmentAdd: (requestData) => dispatch(investmentAdd(requestData)),
    getStageAndIndustry: (requestData) => dispatch(getStageAndIndustry(requestData)),
   
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ApplyInvestmentForm));