import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { image_url } from "../../config/Api";


export const OurOfferings = ({ data}) => {
    return (
        <Fragment>

            

            <div class="fund-middle-content">
                <div class="container">
                    <div class="title-black text-center">
                        <h1>Our Offerings</h1>
                    </div>
                    <div class="fund-middle-content-block our-offering">
                        <div class="row">
                            {data && data.map((row,key)=>{
                                return(
                                    <Fragment>
                                        {row.list && row.list.map((prow,key)=>{

                                            return(
                                                <Fragment>
                                                
                                                    <div class="col-md-4">
                                                        <div class="fund-block-single">
                                                            <div class="fund-block-icon">
                                                                <img src={image_url+''+prow.image} alt=""></img>
                                                            </div>
                                                            <h4>{prow.title ? prow.title : ''}</h4>
                                                        </div>
                                                    </div>
                                                    

                                                </Fragment>
                                            )
                                        })}

                                    </Fragment>
                                )
                            })}
                            
                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    )
}

