import { createStore, applyMiddleware,combineReducers,compose } from 'redux';
import thunk from 'redux-thunk';
import Users from '../reducers/Users';
import Header from '../reducers/Header';
import Footer from '../reducers/Footer';
import Content from '../reducers/Content';
import Utils from '../reducers/Utils';
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__||compose;
const composeEnhancers = compose;

export default ()=>{
    const store = createStore(combineReducers({
        user:Users,
        header:Header,
        footer:Footer,
        contents:Content,
        utils:Utils,
    }),composeEnhancers(applyMiddleware(thunk)));
    return store;
}