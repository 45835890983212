import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link,withRouter } from "react-router-dom";
import { getSetting, OurStoryData, PageContent, setPathLayout,CorporateSponsor } from "../actions/Utils";
import { image_url, host_name } from '../config/Api';
import Htmlcontent from '../components/Htmlcontent';
import BannerLeft from '../components/page/BannerLeft';
import Banner from '../img/banner3.png';


// import {CorporateSponsorItem} from '../components/corporate-sponsor/CorporateSponsorItem';

class AboutusLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            path:'',
            header:{},
            container:{},
            footer:{},
            layout_id:'',
            page_content:{},
            our_story_data:{},
            corporate_sponsor_data:{},
            load_more:false,
            show_less:false
        };
    }
    componentDidMount = () => {
        // console.log('hello world');
         window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        

       
        const slug = this.props.match.path.replace('/','');

       // console.log(slug);

        this.props.PageContent(slug).then((res)=>{

        });

        this.props.OurStoryData().then((res)=>{
            this.setState({
                our_story_data:res
              })
        });

        this.props.CorporateSponsor().then((res)=>{
            this.setState({
                corporate_sponsor_data:res
              })
        });
    }

    componentDidUpdate= () => {
        

        // console.log('state ==',this.state.show_less);

        if(this.state.show_less && this.state.load_more){
            
            // console.log('update');
            window.scroll({ top: 0, left: 0, behavior: 'smooth' });

        }else{

            console.log('scroll no');

            

        }

        // if(!load_more && !show_less){

        //     window.scroll({ top: 0, left: 0, behavior: 'smooth' });

        // }

        

    }

    LoadMoreOn = () => {
        this.setState({
            load_more: true,
            show_less: false,
            
        })

    }

    LoadLessOn = () => {
        this.setState({
            load_more: false,
            show_less: true,
            
        })

    }

    render() {
        const {page_data}=this.props.page_content.content;
        const {our_story_data,load_more,show_less} = this.state;
        const {corporate_sponsor_data} = this.state;
        const lang = this.props.lang;
        const { bread_curmbs } = this.props.page_content.content;

        console.log('page_data= ',page_data);

        // const {section}=this.props.page_content.content.section;
        var contents_top = [];
        var mission_vision_content = [];
        var mission_vision_content_image;
        var contents_bottom_list = [];
        var section_data_top = {};
        var about_mission_vision = {};
        var section_data_bottom_list = {};
        
        

        if(page_data){

            const {section} = page_data;
            console.log('sectiondata',section);
            if(section){
            section_data_top = section.find((row) => {

                if (row.name) {
                    if (row.name.toLowerCase() == 'about top content') {
                        return true;
                    }
                    else {
                        return false;
                    }
                }

                else {
                    return false;
                }
            });

            

            if (section_data_top) {
                contents_top = section_data_top.contents[0]; //about top content load

            }
            
            // console.log('contents_top',contents_top);

            about_mission_vision = section.find((row) => {

                if (row.name) {
                    if (row.name.toLowerCase() == 'about mission and vision section') {
                        return true;
                    }
                    else {
                        return false;
                    }
                }

                else {
                    return false;
                }
            });

            if (about_mission_vision) {
                mission_vision_content = about_mission_vision.contents[0].list; //top story load
                mission_vision_content_image = about_mission_vision.contents[0].listcontent.image;
                

            }

     

        } 
        }

        // console.log("contents_top =", contents_top);

        // console.log("mission_vision_content =", mission_vision_content.listcontent);
        // // console.log(mission_vision_content.length);

        // console.log("contents_bottom_list  =", contents_bottom_list);


            return (
                
                
                <Fragment>
                

                <div class="inner-header-bottom">
                    <div class="container pt-60 pb-15">
                        <div class="row align-items-center mt-30">
                            <div class="col-lg-6">
                                <h3>ABOUT EDUCLERK</h3>
                                <p>
                                    Educlerk is multipurpose educational institute management software which use by any educatinal institute for all administrator, management and learning related activites, Using Educlerk management information system you can manage students parents, teachers, employees, courses and all the system and process related to running your institute efficiently 
                                </p>
                            </div>
                            <div class="col-lg-6 pr-50 pl-50 text-center">
                                <img src={Banner} alt=""></img>
                            </div>
                        </div>
                    </div>
                </div>
                

                <section class="pt-70 pb-70">
                    <div class="container">
                        <div class="bg-content pt-50 pb-50 pr-20 pl-20">
                            <div class="title-style-one">
                                <h3>What we Believe?</h3>
                                <p>
                                    We firmly believe in the quote by Leonardo Da Vinci “Simplicity is the ultimate sophistication” and all of our solutions are designed keeping this in mind.{'\n'} Our clients love us for our friendly attitude, understanding of requirements, technical expertise and ready availability for help.
                                </p>
                                <p>
                                    We also believe that learning never ends hence always look forward to become knowledge partners with educators.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div class="container">
                        <div class="title-style-one">
                            <h3>The Key benefits Of Educlerk </h3>
                            <p>The software is usable in all organization</p>
                        </div>
                        <div class="erp-content d-flex flex-wrap justify-content-center mb-50 mt-30">
                            <div class="erp-item  mb-4">
                                <i class="fa-solid fa-circle-check  mb-20"></i>
                                <h3  class="mb-0">Online based software</h3>
                            </div> 
                            <div class="erp-item  mb-4">
                                <i class="fa-solid fa-circle-check  mb-20"></i>
                                <h3  class="mb-0">Hosted in secured server</h3>
                            </div> 
                            <div class="erp-item  mb-4">
                                <i class="fa-solid fa-circle-check  mb-20"></i>
                                <h3  class="mb-0">User friendly interface</h3>
                            </div> 
                            <div class="erp-item  mb-4">
                                <i class="fa-solid fa-circle-check  mb-20"></i>
                                <h3  class="mb-0">Optimization option</h3>
                            </div> 
                            <div class="erp-item  mb-4">
                                <i class="fa-solid fa-circle-check  mb-20"></i>
                                <h3  class="mb-0">Freedom of use</h3>
                            </div> 
                            <div class="erp-item  mb-4">
                                <i class="fa-solid fa-circle-check  mb-20"></i>
                                <h3  class="mb-0">Mostly Secure and Reliable</h3>
                            </div> 
                            <div class="erp-item  mb-4">
                                <i class="fa-solid fa-circle-check  mb-20"></i>
                                <h3  class="mb-0">24/7/365 Support</h3>
                            </div> 
                            <div class="erp-item  mb-4">
                                <i class="fa-solid fa-circle-check  mb-20"></i>
                                <h3  class="mb-0">Very reasonable price </h3>
                            </div> 
                            <div class="erp-item  mb-4">
                                <i class="fa-solid fa-circle-check  mb-20"></i>
                                <h3  class="mb-0">Almost free customization </h3>
                            </div> 
                            <div class="erp-item  mb-4">
                                <i class="fa-solid fa-circle-check  mb-20"></i>
                                <h3  class="mb-0">Fully compatible </h3>
                            </div> 
                        </div>
                    </div>
                </section>

              

            </Fragment>  
                
            )
        
   }
}

const mapStateToProps = state => ({
    page_content:state.contents.page_content,
    utils:state.utils,
    header:state.header,
    contents:state.contents,
    footer:state.footer,
    layout_id:state.utils.layout_id,
    routemenu:state.utils.routemenu,
    setting:state.utils.setting,
    lang:state.utils.lang
    
})

const mapDispatchToProps = dispatch => ({
    getSetting:() =>dispatch(getSetting()),
    setPathLayout:(requestData) =>dispatch(setPathLayout(requestData)),
    PageContent: (requestData) => dispatch(PageContent(requestData)),
    OurStoryData: (requestData) => dispatch(OurStoryData(requestData)),
    CorporateSponsor: (requestData) => dispatch(CorporateSponsor(requestData)),
})

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(AboutusLayout));