import React, { useState } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';

export const DateInput = (props) => {
//   const [startDate, setStartDate] = useState(new Date());
   let textInput = React.createRef();
   const [startDate, setStartDate] = useState(null);

  function handledatetime(date) {

    setStartDate(date);
    setTimeout(() => {
        if(props.id == 'dateofbirth') {
            props.handlePoliciesBasedOnAge();
        }
        if(props.id == 'nominee_dob') {
            props.handleNomineePorichoy();
        }
       
    }, 500);
    
  }
  return (
    <DatePicker 
    selected={startDate} 
    onChange={(date) => handledatetime(date)}
    className="form-control"
    ref={textInput}
    placeholderText="Date"
    id={props.id}
    dateFormat="MM/dd/yyyy"
    peekNextMonth
    showMonthDropdown
    showYearDropdown
    dropdownMode="select"
    />
  );
};