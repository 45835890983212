import qs from 'qs';
import axios from 'axios';
import axiosInstance, { caversion, version,axiosPorichoy } from '../config/Api';
export const setPath = (path) => ({
  type: 'SET_PATH',
  path
});
export const setLayout = (id) => ({
  type: 'SET_LAYOUT_ID',
  id
});
export const setPathLayout = (data) => ({
  type: 'SET_PATH_LAYOUT',
  data
});

export const initHeader = (data) => ({
  type: 'INIT_HEADER',
  data
});
export const setHeaderLoading = (status) => ({
  type: 'SET_HEADER_LOADING',
  status
});
export const setFooterLoading = (status) => ({
  type: 'SET_FOOTER_LOADING',
  status
});




export const getHeaderSection = () => {
  return (dispatch) => {
    return axiosInstance.get(version + 'get/header', qs.stringify()).then(res => {
      if (Object.keys(res.data).length) {
        dispatch(initHeader(res.data));
        dispatch(setHeaderLoading(false));
      }
      else {
        dispatch(setHeaderLoading(false));
      }
      return res.data;
    });
  }
}

export const initTopMenuHeader = (data) => ({
  type: 'INIT_TOPMENU',
  data
});

export const getTopMenu = () => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/topmenu', qs.stringify()).then(res => {
      if (Object.keys(res.data).length) {
        dispatch(initTopMenuHeader(res.data));
        dispatch(setHeaderLoading(false));
      }
      else {
        dispatch(setHeaderLoading(false));
      }
      return res.data;
    });
  }
}
export const initRightSiteMenuHeader = (data) => ({
  type: 'INIT_RIGHTSITE',
  data
});

export const getRightSiteMenu = () => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/rightmenu', qs.stringify()).then(res => {
      if (Object.keys(res.data).length) {
        dispatch(initRightSiteMenuHeader(res.data));
      }

      return res.data;
    });
  }
}

export const initFooter = (data) => ({
  type: 'INIT_FOOTER',
  data
});


export const sendContactus = (parms) => {

  return (dispatch) => {
    return axiosInstance.post(version + 'sendContactus', qs.stringify(parms)).then(res => {

      return res;
    });
  }

}

export const sendCareer = (parms) => {

  return (dispatch) => {
    return axiosInstance.post(version + 'sendCareer', qs.stringify(parms)).then(res => {

      return res;
    });
  }

}

export const sendInvestNow = (parms) => {

  return (dispatch) => {
    return axiosInstance.post(version + 'sendInvestNow', qs.stringify(parms)).then(res => {

      return res;
    });
  }

}
export const sendInvestWithus = (parms) => {

  return (dispatch) => {
    return axiosInstance.post(version + 'sendInvestWithus', qs.stringify(parms)).then(res => {

      return res;
    });
  }

}
export const getaQuote = (parms) => {


  return (dispatch) => {
    return axiosInstance.post(version + 'sendContactus', qs.stringify(parms)).then(res => {

      return res.data;
    });
  }
}
export const getFooterSection = () => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/footer_navigation', qs.stringify()).then(res => {
      // console.log("getFooterSection section = ",res.data);
      if (Object.keys(res.data).length) {
        dispatch(initFooter(res.data));
        dispatch(setFooterLoading(false));
      }
      else {
        dispatch(setFooterLoading(false));
      }
      return res.data;
    });
  }
}
export const getPubFooterSection = () => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/pubfooter_navigation').then(res => {
      // console.log("getFooterSection section = ",res.data);
      if (Object.keys(res.data).length) {
        dispatch(initFooter(res.data));
        dispatch(setFooterLoading(false));
      }
      else {
        dispatch(setFooterLoading(false));
      }
      return res.data;
    });
  }
}



export const contentAdded = (data, id) => ({
  type: 'ADDED_CONTENT',
  data: data,
  id: id
});

export const contentLoadStatus = (id) => ({
  type: 'CONTENT_LOAD_STATUS',
  id: id
});


export const sectionandContent = (parms) => {

  var string = '';
  var length = Object.entries(parms).length;
  Object.entries(parms).forEach((ele, key) => {
    string += ele[0] + '=' + ele[1]
    if (length - 1 !== key) {
      string += '&'
    }
  });

  return (dispatch) => {
    return axiosInstance.get(version + 'get/contents?' + string, qs.stringify()).then(res => {
      // console.log("sectionandContent section = ",res.data);
      if (Object.keys(res.data).length) {
        dispatch(contentAdded(res.data, parms.menu_id));
      }
      if (Object.keys(res.data).length === 0) {
        dispatch(contentLoadStatus(parms.menu_id));
      }
      return res;
    });
  }
}


export const initRoute = (data) => ({
  type: 'INIT_ROUTES',
  data
});

export const initpagename = (data) => ({
  type: 'INIT_PAGE_NAME',
  data
});


export const getRoutes = () => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/routes', qs.stringify()).then(res => {
      if ((res.data).length) {
        dispatch(initRoute(res.data));
        // console.log(" res.data = ",res.data);
        // dispatch(initpagename(res.data));
      }
      return res.data;
    });
  }
}

export const initPubRoute = (data) => ({
  type: 'INIT_PUBLICATION_ROUTES',
  data
});
export const getPubRoutes = () => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/pubroutes', qs.stringify()).then(res => {
      if ((res.data).length) {
        dispatch(initPubRoute(res.data));

      }
      return res.data;
    });
  }
}
export const setSetting = (data) => ({
  type: 'SET_SETTING',
  data
});
export const setSearchKey = (search) => ({
  type: 'SET_SEARCH_KEY',
  search
});


export const getSetting = () => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/setting').then(res => {
      if (Object.keys(res.data).length) {
        dispatch(setSetting(res.data));
      }
      return res.data;
    });
  }
}

export const setAllNavigation = (data) => ({
  type: 'INIT_HEADER',
  data
});

export const getAllNavigation = () => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/all_navigation').then(res => {
      if ((res.data).length) {
        dispatch(setAllNavigation(res.data));
      }
      return res.data;
    });
  }
}

export const getHomeSliderData = () => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/home_slider').then(res => {
      if ((res.data).length) {
        dispatch(setAllNavigation(res.data));
      }
      return res.data;
    });
  }
}

export const getContactusModeSetting = () => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/contactus_mode_setting').then(res => {
      if ((res.data).length) {
        dispatch(setAllNavigation(res.data));
      }
      return res.data;
    });
  }
}

export const getCareerDesignationAndPosition = () => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/career_designation_position').then(res => {
      if ((res.data).length) {
        dispatch(setAllNavigation(res.data));
      }
      return res.data;
    });
  }
}

export const getStageAndIndustry = () => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/investment_stage_industry').then(res => {
      if ((res.data).length) {
        dispatch(setAllNavigation(res.data));
      }
      return res.data;
    });
  }
}


export const setTopNavigation = (data) => ({
  type: 'INIT_TOPMENU',
  data
});


export const getTopNavigation = (parms) => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/navigation', { params: parms }).then(res => {
      if ((res.data).length) {
        dispatch(setTopNavigation(res.data));
      }
      return res.data;
    });
  }
}
export const setHeaderNavigation = (data) => ({
  type: 'INIT_HEADERMENU',
  data
});


export const getHeaderNavigation = (parms) => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/navigation', { params: parms }).then(res => {
      if ((res.data).length) {
        dispatch(setHeaderNavigation(res.data));
      }
      return res.data;
    });
  }
}

export const initSearchContent = (data) => ({
  type: 'INIT_SEARCH_CONTENT',
  data
});
export const addSearchContent = (data) => ({
  type: 'ADD_SEARCH_CONTENT',
  data
});

export const getsearch = (parms) => {

  var string = '';
  var length = Object.entries(parms).length;
  Object.entries(parms).forEach((ele, key) => {
    string += ele[0] + '=' + ele[1]
    if (length - 1 !== key) {
      string += '&'
    }
  });

  return (dispatch) => {
    return axiosInstance.get(version + 'get/contentsearch?' + string).then(res => {

      if (Array.isArray(res.data)) {
        if (parms.initial === true) {
          dispatch(initSearchContent(res.data));
        }
        else {
          dispatch(addSearchContent(res.data));
        }

      }
      return res.data;
    });
  }
}


export const detailsContent = (data) => ({
  type: 'SET_DETAILS_CONTENT',
  data
});

export const getContentDetails = (parms) => {

  var string = '';
  var length = Object.entries(parms).length;
  Object.entries(parms).forEach((ele, key) => {
    string += ele[1]
    if (length - 1 !== key) {
      string += '&'
    }
  });

  return (dispatch) => {
    return axiosInstance.get(version + 'get/content-details?slug=' + string).then(res => {

      dispatch(detailsContent(res.data));
      return res.data;
    });
  }
}






//Mobarak Hossen

export const SetHomeContentAdded = (data) => ({
  type: 'INIT_HOME_LAYOUT',
  data: data,

});

export const homeLayoutContent = (parms) => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/home').then(({ data }) => {
      // console.log("sectionandContent section = ", data);
      if (Object.keys(data).length) {
        dispatch(SetHomeContentAdded(data));

        if (data.page_data.page_meta_data) {
          dispatch(SetMetaData(data.page_data.page_meta_data));
        }

      }
      return data;

    });
  }
}
export const SetFaqContentAdded = (data) => ({
  type: 'INIT_FAQ_LAYOUT',
  data: data,

});

export const faqLayoutContent = (parms) => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/sectionandContent', { params: parms }).then(({ data }) => {

      if (Object.keys(data).length) {
        dispatch(SetFaqContentAdded(data));
      }
      return data;

    });
  }
}
export const SetContactusContentAdded = (data) => ({
  type: 'INIT_CONTACTUS_LAYOUT',
  data: data,

});

export const contactusLayoutContent = (parms) => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/sectionandContent', { params: parms }).then(({ data }) => {

      if (Object.keys(data).length) {
        dispatch(SetContactusContentAdded(data));
      }
      return data;

    });
  }
}
export const SetPremiumCalculatorContentAdded = (data) => ({
  type: 'INIT_PREMIUM_CALCULATOR_LAYOUT',
  data: data,

});

export const premiumCalculatorLayoutContent = (parms) => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/sectionandContent', { params: parms }).then(({ data }) => {

      if (Object.keys(data).length) {
        dispatch(SetPremiumCalculatorContentAdded(data));
      }
      return data;

    });
  }
}

export const SetPageContent = (data) => ({
  type: 'INIT_PAGE_LAYOUT',
  data: data,

});

export const PageContent = (parms) => {

  let requestData = {
    slug: "/" + parms
  }
  return (dispatch) => {
    return axiosInstance.get(version + 'get/page', { params: requestData }).then(({ data }) => {
      // console.log("data Mobarak=", data);
      if (Object.keys(data).length) {
        dispatch(SetPageContent(data));

        if (data.page_data.page_meta_data) {
          dispatch(SetMetaData(data.page_data.page_meta_data));
        }

      }
      return data;

    });
  }
}
export const getEvent = () => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/getEvent').then(({ data }) => {

      return data;

    });
  }
}

export const OurStoryData = () => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/getOurStory').then(({ data }) => {

      return data;

    });
  }
}



export const CorporateSponsor = () => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/getCorporateSponsor').then(({ data }) => {

      return data;

    });
  }
}

export const getExploreUpdateContent = (parms) => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/getExploreUpdateContent', { params: parms }).then(({ data }) => {

      return data;

    });
  }
}

export const pageSectionContent = (parms) => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/sectionandContent', { params: parms }).then(({ data }) => {
      return data;

    });
  }
}

export const newsSubscribe = (parms) => {

  return (dispatch) => {
    return axiosInstance.post(version + 'newsSubscribe', qs.stringify(parms)).then(res => {
      return res;
    });
  }

}


export const SetNewsContent = (data) => ({
  type: 'INIT_NEWS_LAYOUT',
  data: data,

});

export const NewsContent = (parms) => {

  let requestData = {
    slug: "/" + parms
  }
  return (dispatch) => {
    return axiosInstance.get(version + 'get/web_news', { params: requestData }).then(({ data }) => {
      if (Object.keys(data).length) {
        dispatch(SetNewsContent(data));

        const metadata = {
          meta_title: data.title,
          meta_description: data.short_description,
          meta_keywords: 'ICAB',
        }
        dispatch(SetMetaData(metadata));
      }
      return data;

    });
  }
}


export const getAllNewsEvent = (category) => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/getAllNewsEvent', { params: { 'category': category } }).then(({ data }) => {

      return data;

    });
  }
}

export const Search = (query) => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/search', { params: { 'search_query': query } }).then(({ data }) => {

      return data;

    });
  }
}

export const MemberData = () => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/council-member').then(({ data }) => {

      return data;

    });
  }
}



export const getAlbums = (parms) => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/getAlbums', {
      params: parms
    }).then(({ data }) => {
      return data
    });
  }
}
export const getGallerys = (parms) => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/getgallerys', {
      params: parms
    }).then(({ data }) => {
      return data
    });
  }
}


export const SetWebNewsCategory = (data) => ({
  type: 'INIT_WEB_NEWS_CATEGORY',
  data: data,
});

export const WebNewsCategory = (parms) => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/getWebNewsCateogry').then(({ data }) => {

      dispatch(SetWebNewsCategory(data));

      return data;

    });
  }
}


export const SetWebNewsFilter = (data) => ({
  type: 'INIT_WEB_NEWS_FILTER',
  data: data,
});

export const WebNewsFilter = (parms) => {
  return (dispatch) => {
    return axiosInstance.post(version + 'get/getWebNewsFilter', qs.stringify(parms)).then(({ data }) => {
      dispatch(SetWebNewsFilter(data));
      // console.log("data yer =",data);
      if (data.length) {
        dispatch(SetWebNewsFilter(data));
      }

      return data;

    });
  }
}
export const SetGroupOfCommittet = (data) => ({
  type: 'INIT_GROUPOF_COMMITTE',
  data: data,
});

export const getGroupOfCommitte = (parms) => {
  return (dispatch) => {
    return axiosInstance.get(version + 'get/getCommittee', { params: parms }).then(({ data }) => {
      dispatch(SetGroupOfCommittet(data));
      if (data.length) {
        dispatch(SetGroupOfCommittet(data));
      }

      const meta_data = {
        meta_title: 'ICAB | Committees ',
        meta_description: '',
        meta_keywords: 'ICAB, ICAB Committees',
      }
      dispatch(SetMetaData(meta_data));

      return data;
    });
  }
}


export const SetSingleOfCommittet = (data) => ({
  type: 'INIT_SINGLEOF_COMMITTE',
  data: data,
});

export const getSingleOfCommitte = (parms) => {
  return (dispatch) => {
    return axiosInstance.get(version + 'get/getCommittee', { params: parms }).then(({ data }) => {
      dispatch(SetSingleOfCommittet(data));

      if (data.length) {
        dispatch(SetSingleOfCommittet(data));
      }

      const meta_data = {
        meta_title: 'ICAB | ' + parms.slug,
        meta_description: '',
        meta_keywords: 'ICAB, ICAB Committees',
      }
      dispatch(SetMetaData(meta_data));

      return data;

    });
  }
}


export const CAMemberData = (requestData) => {

  // return (dispatch) => {
  //   return casInstance.get(caversion + 'memberinfopagination', { params: requestData }).then(({ data }) => {

  //     return data;

  //   });
  // }
}


export const memberDetails = (requestData) => {

  // return (dispatch) => {
  //   return icabInstance.get('IcabApi/TWCPDevent/GetMemberDetails', { params: requestData }).then(({ data }) => {

  //     return data;

  //   });
  // }
}

export const cpdInfo = (requestData) => {

  // return (dispatch) => {
  //   return icabInstance.get('IcabApi/TWCPDevent/GetCPDinformation', { params: requestData }).then(({ data }) => {

  //     return data;

  //   });
  // }
}

export const getCslDheerajapi = (requestData, actionMethod, methodname = 'get') => {

  // return (dispatch) => {

  //   if (methodname == 'get') {
  //     return cslDheerajApi.get(actionMethod, { params: requestData }).then(({ data }) => {

  //       return data;

  //     });
  //   }
  //   else if (methodname == 'post') {

  //   }

  // }
}


export const CAFirmData = (requestData) => {

  
}

export const initModalPopupAds = (data) => ({
  type: 'INIT_MODAL_POPUP_ADS',
  data: data,
});

export const getHomePageModal = () => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/modalPopup').then(({ data }) => {
      dispatch(initModalPopupAds(data));
      return data;

    });
  }
}



export const setMemberToken = (token) => ({
  type: 'SET_MEMBER_TOKEN',
  data: token,
});

export const userLogin = (requestData) => {

  // return (dispatch) => {
  //   return casInstance.post(caversion + 'userlogin', requestData).then(({ data }) => {
  //     if (data.message) {
  //       dispatch(setMemberToken(data.accesstoken));
  //     }
  //     else {
  //       dispatch(setMemberToken(''));
  //     }
  //     return data;

  //   });
  // }
}
export const addPushWebToken = (requestData) => {

  return (dispatch) => {
    return axiosInstance.post(version + 'pushwebnitification', qs.stringify(requestData)).then(({ data }) => {
      return data;

    });
  }
}

export const SetMetaData = (data) => ({
  type: 'INIT_META_DATA',
  data: data,

});

export const getMetaData = (parms) => {

  return (dispatch) => {
    // console.log("get meta data =", parms);
    // return axiosInstance.get(version + 'get/sectionandContent', {params:parms}).then(({ data }) => {

    //   if (Object.keys(data).length) {
    //     dispatch(SetMetaData(data));
    //   }
    //   return data;

    // });
  }
}
export const getPoliciesBasedOnAge = (parms) => {

  return (dispatch) => {

    return axiosInstance.post(version + 'getPoliciesBasedOnAge', qs.stringify(parms)).then(({ data }) => {
      // console.log('getpolicies =', data);
      return data;
      // console.log("getPoliciesBasedOnAge =", data);

    });
  }
}
export const getTermsBasedOnPolicy = (parms) => {

  return (dispatch) => {

    return axiosInstance.post(version + 'getTermsBasedOnPolicy', qs.stringify(parms)).then(({ data }) => {
      // console.log('getTermsBasedOnPolicy =', data);
      return data;

    });
  }
}
export const getSumAssuredValidation = (parms) => {

  return (dispatch) => {

    return axiosInstance.post(version + 'getSumAssuredValidation', qs.stringify(parms)).then(({ data }) => {
      // console.log('getSumAssuredValidation =', data);
      return data;

    });
  }
}
export const getCalculations = (parms) => {

  return (dispatch) => {

    return axiosInstance.post(version + 'getCalculations', qs.stringify(parms)).then(({ data }) => {
      // console.log('getCalculations =', data);
      return data;

    });
  }
}


export const productDetails = (parms) => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/productDetails', { params: parms }).then(({ data }) => {
      // console.log('get productDetails =', data);
      return data;
    });
  }
}


export const SetChangeLanguage = (data) => ({
  type: 'SET_LANG',
  data: data
})


export const SetLayoutContentLoad = (data) => ({
  type: 'INIT_COMMON_CONTENT',
  data: data,

});

export const layoutContentLoad = (parms) => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/sectionandContent', { params: parms }).then(({ data }) => {

      if (Object.keys(data).length) {
        dispatch(SetLayoutContentLoad(data));
      }
      return data;

    });
  }
}
export const customerPoricyNid = (parms) => {
//production  = nid-person
// for testing = test-nid-person-values

  return (dispatch) => {
    return axiosPorichoy.post('test-nid-person-values', parms).then(({ data }) => {
      
      return data;

    });
  }
}

export const policyAdd = (parms) => {

  return (dispatch) => {
    return axiosInstance.post(version + 'policy/policy_add', qs.stringify(parms)).then(({ data }) => {

      // if (Object.keys(data).length) {
      //   dispatch(SetLayoutContentLoad(data));
      // }
      return data;

    });
  }
}

export const policyNomineeAdd = (parms) => {

  return (dispatch) => {
    return axiosInstance.post(version + 'policy/policy_nominee_add', qs.stringify(parms)).then(({ data }) => {
      return data;

    });
  }
}
export const paymentCustomerValidation = (parms) => {

  return (dispatch) => {
    return axiosInstance.post(version + 'policy/policy_validation', qs.stringify(parms)).then(({ data }) => {
      return data;

    });
  }
}
export const paymentCustomerAmountValidation = (parms) => {

  return (dispatch) => {
    return axiosInstance.post(version + 'policy/policy_amount_validation', qs.stringify(parms)).then(({ data }) => {
      return data;

    });
  }
}
export const userpanelDashboard = (parms) => {

  return (dispatch) => {
    return axiosInstance.post(version + 'userpanel/dashboard', qs.stringify(parms)).then(({ data }) => {
      return data;

    });
  }
}
export const userpanelPolicyDetails = (parms) => {

  return (dispatch) => {
    return axiosInstance.post(version + 'userpanel/policy-details', qs.stringify(parms)).then(({ data }) => {
      return data;

    });
  }
}
export const getPolicyInvoice = (parms) => {

  return (dispatch) => {
    return axiosInstance.post(version + 'userpanel/policyinvoice', qs.stringify(parms)).then(({ data }) => {
      return data;
    });
  }
}
export const changeCustomerPassword = (parms) => {

  return (dispatch) => {
    return axiosInstance.post(version + 'userpanel/changepassword', qs.stringify(parms)).then(({ data }) => {
      return data;

    });
  }
}
export const getPople = () => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/people').then(({ data }) => {
      return data;
    });
  }
}

export const getPortfolio = () => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/portfolio').then(({ data }) => {
      return data;
    });
  }
}

export const getIndustry = () => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/industry').then(({ data }) => {
      return data;
    });
  }
}

export const getPortfolioStatus = () => {

  return (dispatch) => {
    return axiosInstance.get(version + 'get/portfoliostatus').then(({ data }) => {
      return data;
    });
  }
}

export const getPopleDetails = (parms) => {

  return (dispatch) => {
    return axiosInstance.post(version + 'get/people/details',qs.stringify(parms)).then(({ data }) => {
      return data;
    });
  }
}

export const getFundDocumentData = (slug) => {

  return (dispatch) => {
    return axiosInstance.post(version + 'get/fund/documents',qs.stringify(slug)).then(({ data }) => {
      return data;
    });
  }
}

export const getFundPerformanceData = (slug) => {

  return (dispatch) => {
    return axiosInstance.post(version + 'get/fund/history',qs.stringify(slug)).then(({ data }) => {
      return data;
    });
  }
}

export const getWebContentDetails = (parms) => {

  return (dispatch) => {
    return axiosInstance.post(version + 'get/webcontent/details',qs.stringify(parms)).then(({ data }) => {
      return data;
    });
  }
}


export const getProfileDetails = (parms) => {

  return (dispatch) => {
    return axiosInstance.post(version + 'get/profile/details',qs.stringify(parms)).then(({ data }) => {
      return data;
    });
  }
}

export const getCorporateSponsorDetails = (parms) => {

  return (dispatch) => {
    return axiosInstance.post(version + 'get/corporate-sponsor/details',qs.stringify(parms)).then(({ data }) => {
      return data;
    });
  }
}


export const investmentAdd = (parms) => {
  const config = {     
    headers: { 'content-type': 'multipart/form-data' }
}
  return (dispatch) => {
    // console.log('data sent = ',parms);return;
    return axiosInstance.post(version + 'investment/add', parms).then(({ data }) => {
      console.log('data sent = ',data);
      // if (Object.keys(data).length) {
      //   dispatch(SetLayoutContentLoad(data));
      // }
      return data;

    });
  }
}

export const careerAdd = (parms) => {
  const config = {     
    headers: { 'content-type': 'multipart/form-data' }
}
  return (dispatch) => {
    return axiosInstance.post(version + 'career/add', parms).then(({ data }) => {

      // if (Object.keys(data).length) {
      //   dispatch(SetLayoutContentLoad(data));
      // }
      return data;

    });
  }
}