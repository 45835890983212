import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from "react-router-dom";
import { pageSectionContent, PageContent, getSetting, setPathLayout, getPople } from "../../actions/Utils";

import Htmlcontent from '../../components/Htmlcontent';
import BreadCrumbs from '../../components/page/BreadCrumbs';
import { image_url, host_name } from '../../config/Api';
import BannerLeft from '../page/BannerLeft';


class People extends Component {
    constructor(props) {
        super(props);
        this.state = {
            path: '',
            contents: [],
            page_content:{},
            content_load: false,
            content_row:this.props.appContext.state.peopleTabSelected 
        };
    }
    componentDidMount = () => {
        // alert(this.props.appContext.state.peopleTabSelected)
        
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        const { path } = this.props.match;

        const slug = this.props.match.path.replace('/','');

        this.props.getPople().then((data) => {
            console.log("pageSectionContent =", data);
            this.setState({
                contents: data,
                content_load: true
            })
        });

        this.props.PageContent(slug).then((res)=>{

        });

    }

    setPathLayout = (parms) => {
        this.props.setPathLayout(parms);
    }
    getSetting = async () => {
        let data = await this.props.getSetting();
    }
    static getDerivedStateFromProps(props) {
        return {
            path: props.utils.path,
            header: props.header,
            container: props.container,
            footer: props.footer,
            layout_id: props.utils.layout_id,
            lang: props.lang

        };
    }


    render() {

        
        const { contents, content_load,content_row } = this.state;
        console.log("contents =", contents)

        const {page_data}=this.props.page_content.content;


        // console.log('page_data =', page_data);

        return (
            <Fragment>

                {page_data && <Fragment>
                
                {page_data.image && <Fragment> 
                {/* image load first */}

                {page_data && <Fragment>

                <section class="inner-header">
                    <img src={page_data.image ? image_url + page_data.image : `${host_name}img/innerbaner.png`} alt=""></img>
                    <h1>{page_data.title}</h1>
                    <div class="inner-breadcrumb">
                        <ul class="nav justify-content-center">
                            <li><Link to={{ pathname: `/home` }} >Home</Link></li>
                            <li>{page_data.title} </li>
                        </ul>
                    </div>
                    <div class="overlay"></div>
                </section>

                </Fragment> }



                {/* <BannerLeft title="People" menu_name={''} banner={''}/> */}

                <div class="tab tab--7 mb-5 people">
                {contents.length ? <Fragment>
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="tab_nav7">
                                
                                    <ul class="nav justify-content-center" id="tab7" role="tablist">
                                    {contents.map((row, key) => {
                                        return (
                                            <li class="nav-item">
                                                <a className={`nav-link ${row.category_id == content_row ? 'active' : ''}`} id="tab7_nav1" data-toggle="tab" href={`#tab7_content${row.category_id}`} role="tab" aria-controls="tab7_nav1" aria-selected="true">{row.category_name ? row.category_name : ''}</a>
                                            </li>
                                        )
                                        })}
                          
                                    </ul>
                                </div>
                            </div>
                        </div>
                        

                        <div class="row">
                            <div class="col-md-12">
                                <div class="tab-content tab--content7" id="tabcontent7">
                                {contents.map((row, key) => {
                                return (
                                    <Fragment>
                                        <div className={`tab-pane fade ${row.category_id == content_row ? 'show active' : ''}`} id={`tab7_content${row.category_id}`} role="tabpanel" aria-labelledby="tab7_nav1">
                                        {row.WebContent.length ? <Fragment>
                                            {row.WebContent.map((prow, pkey) => {
                                                return (
                                            <div class="people-single">
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <div class="people-image">
                                                            <img src={image_url+''+prow.image_long} alt=""></img>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <div class="people-content">
                                                            <h3><Link to={{pathname:`/people/${prow.id}/${prow.slug}`}} >{prow.name}</Link> </h3>
                                                            <h5>{prow.designation ? prow.designation :''}</h5>
                                                            <p>
                                                                {prow.short_description} 
                                                            </p>
                                                            <div class="people-social">

                                                                {prow.facebook && <a target='_blank' href={prow.facebook}><i className="fab fa-facebook-f"></i> 
                                                                        
                                                                </a>}
                                                                {prow.linkedin &&  <a target='_blank' href={prow.linkedin}><i className="fab fa-linkedin-in"></i>  
                                                                    
                                                                </a>}
                                                                
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            )
                                            })}
                                        </Fragment> : 'No Record found.'}
                
                                            
                                        </div>

                                    </Fragment>
                                        )
                                    })}

                                </div>
                            </div>
                        </div>

                                        
                        
                    </div>
                </Fragment> : ''}
                </div>

                </Fragment> }
                </Fragment>}                   

            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    page_content:state.contents.page_content,
    utils: state.utils,
    layout_id: state.utils.layout_id,
    setting: state.utils.setting,
    lang: state.utils.lang

})

const mapDispatchToProps = dispatch => ({
    getSetting: () => dispatch(getSetting()),
    setPathLayout: (requestData) => dispatch(setPathLayout(requestData)),
    PageContent: (requestData) => dispatch(PageContent(requestData)),
    pageSectionContent: (requestData) => dispatch(pageSectionContent(requestData)),
    getPople: (requestData) => dispatch(getPople(requestData)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(People));