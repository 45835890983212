import qs from 'qs';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { sendInvestNow, setLayout, setPath, setPathLayout } from '../../actions/Utils';
import axiosInstance, { version } from '../../config/Api';



class InvestNowForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            validator:{
                name:'',
                phone: '',
                email:'',
                organization:'',
                message:'',
                
            },
            message:'',
            message_type:'',
            api_call:false,
        };
        this.refs = React.createRef();
    }

    handleMessageClose = () => {
        this.setState({message:'',message_type:''})
    }

    handleSubmit = () => {
     

      
        const userFormData = {
            name:this.name.value,
            
            email:this.email.value,
            phone:this.phone.value,
            organization:this.organization.value,
            message:this.message.value,
            
        }
       
        const resetValidator = {
            name:'',
            email:'',
            phone:'',
            organization:'',
            message:'',
        }
        var validation = {};
       
        if(userFormData.name == '') {
            validation.name = 'The name field is required.';
        }
        
        
        if(userFormData.email == '') {
            validation.email = 'The email field is required.';
        }

        if(userFormData.phone == '') {
            validation.phone = 'The phone field is required.';
        }
        if(userFormData.organization == '') {
            validation.organization = 'The organization field is required.';
        }
        if(userFormData.message == '') {
            validation.message = 'The message field is required.';
        }
       
        if(Object.keys(validation).length) {
            this.setState({validator:{...resetValidator,...validation},message:''});
            
        }
        else {
        
            this.setState({validator:validation,api_call:true})
            axiosInstance.post(version+'sendInvestNow',qs.stringify(userFormData)).then((res) => {

               
                this.name.value = '';           
                this.email.value = '';
                this.phone.value = '';
                this.organization.value = '';
                this.message.value = '';
                
                this.setState({message:res.data.success,message_type:'success',validator:{...resetValidator},api_call:false})
            })
            .catch(error => {
               
             
                if(typeof error.response.data.errors != "undefined") {
                    for (const [key, value] of Object.entries(error.response.data.errors)) {
                       validation[key] = value;
                    }
                    this.setState({validator:validation,api_call:false})
                }
                else if(error.response) {
                   var html = '';
                    if(Object.keys(error.response.data).length) {

                        for (const [key, value] of Object.entries(error.response.data)) {
                            //console.log(`${key} ${value}`); // "a 5", "b 7", "c 9"
                            html += `${value}`;
                        }
                        
                    }
                    // if(Object.keys(error.response.data).length) {
                    //     for (const [key, value] of Object.entries(error.response.data)) {
                    //         //console.log(`${key} ${value}`); // "a 5", "b 7", "c 9"
                    //         html += `${value}`;
                    //     }
                        
                    // }
                  
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the 
                    // browser and an instance of
                    // http.ClientRequest in node.js
                    // console.log('error.request ',error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    // console.log('Error', error.message);
                }
                if(html){
                    this.setState({message:html,message_type:'danger',validator:{...resetValidator},api_call:false})
                }
                
            })
        }
        
       
    }

  render() {
      
    const contents = this.props.data.contents;
    const {validator,api_call} = this.state;
    var description =contents?contents.description.replace(/<\/?[^>]+(>|$)/g, ""):'';
    return (
        <Fragment>

                <section class="invest-now mb-5">
                    <div class="container">
                        <div className="row">
                            {this.state.message !='' && this.state.message_type =='success' && <div className="col-sm-12" >
                                    <div className="alert  alert-success alert-dismissible fade show" role="alert">
                                        {this.state.message}
                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={()=>this.handleMessageClose()}>
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                </div>}
                                {this.state.message !='' && this.state.message_type =='danger' && <div className="col-sm-12">
                                    <div className="alert  alert-danger alert-dismissible fade show" role="alert">
                                        {this.state.message}
                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={()=>this.handleMessageClose()}>
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                </div>}
                        </div>
                        <form action="">
                            <h3 class="text-center">Let's Get in Touch</h3>
                            <div class="row">
                                <div class="col-md-6">
                                    {/* <label>Full Name <span>*</span></label> */}
                                    <input type="text" ref={(input) => this.name = input} className={validator.name?'form-control input-error':'form-control'} placeholder="Your Name"/>
                                
                                    {validator.name && <span className="help-block form-text text-danger">{validator.name}</span>}
                                    
                                </div>
                                <div class="col-md-6">
                                    {/* <label>Full Name <span>*</span></label> */}
                                    <input type="text" ref={(input) => this.organization = input} className={validator.organization?'form-control input-error':'form-control'} placeholder="Organization"/>
                                
                                    {validator.organization && <span className="help-block form-text text-danger">{validator.organization}</span>}
                                    
                                </div>
                                <div class="col-md-6">
                                    <input type="email" ref={(input) => this.email = input} className={validator.email?'form-control  input-error':'form-control '} placeholder="Email" />
                                    {validator.email && <span className="help-block form-text text-danger">{validator.email}</span>}
                                    
                                </div>
                                <div class="col-md-6">
                                    <input type="text" ref={(input) => this.phone = input} className={validator.phone?'form-control input-error':'form-control '} placeholder="Phone Number" />
                                    {validator.phone && <span className="help-block form-text text-danger">{validator.phone}</span>}
                                    
                                </div>
                                <div class="col-md-12">
                                    <textarea className={validator.message?'form-control  input-error':'form-control '}  ref={(input) => this.message = input} placeholder="Message" rows="6"></textarea>
                            
                                    {validator.message && <span className="help-block form-text text-danger">{validator.message}</span>}
                                    
                                </div>
                            </div>
                            <div class="submit-btn text-center mt-3">
                            {api_call && <input type="button" value="Submit" className="btn btn-primary" name="submit" disabled={true}/>}
                            {!api_call && <input type="button" value="Submit" className="btn btn-primary" name="submit" onClick={()=>this.handleSubmit()}/>}
                                
                            </div>
                            
                        </form>
                    </div>
                </section>

              
        </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) =>({
    setPath : (requestData)=>{dispatch(setPath(requestData))},
    setLayout : (requestData)=>{dispatch(setLayout(requestData))},
    setPathLayout : (requestData)=>{dispatch(setPathLayout(requestData))},
    sendInvestNow : (requestData)=>{dispatch(sendInvestNow(requestData))},
})

export default connect(null,mapDispatchToProps)(InvestNowForm);