import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from "react-router-dom";
// import { image_url } from '../../config/Api';
// import NoImage from '../../img/no_image.jpeg';



class Sitemap extends Component {

  constructor(props) {
    super(props);
    this.state = {
      content: {},
      content_loading: true,
      albumid: '',
      sec_content: {},
      page_content: {},
    }


  }

  componentDidMount = () => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    var id = this.props.match.params.id;

    this.setState({
      sec_content: this.props.sec_content,
      page_content: this.props.page_content
    })
  }


  static getDerivedStateFromProps(props, state) {

    return {
      lang: props.lang
    }

  }



  render() {


    const { content, content_loading } = this.state;


    const lang = this.props.lang;
    const sitemap = this.state.sec_content;


    const ListMenu = ({ dept, data, hasSubMenu, menuName, menuIndex }) => (
      <li>
        <Fragment dept={dept}>

          {data.submenu.length > 0 ? <Link to="" >
            {lang === 'en' && <Fragment>{data.title}</Fragment>}
            {lang === 'bn' && <Fragment>{data.bn_title}</Fragment>}

          </Link> : ''}
          {data.submenu.length == 0 ?
            <Fragment>
              {data.link_type == 1 && <Link to={{ pathname: `${(data.page.layout_id == 3 || data.page.layout_id == 6)?'/page':''}${data.page.slug}` }} >
                {lang === 'en' && <Fragment>{data.title}</Fragment>}
                {lang === 'bn' && <Fragment>{data.bn_title}</Fragment>}
              </Link>}
              {data.link_type == 0 && <Link to={{ pathname: `${data.url}` }} target={data.url_target ? data.url_target : '_blank'}>{lang === 'en' && <Fragment>{data.title}</Fragment>}
                {lang === 'bn' && <Fragment>{data.bn_title}</Fragment>}

              </Link>}
            </Fragment> : ''}

        </Fragment>
        {hasSubMenu && (
          <SubMenu
            dept={dept}
            data={data.submenu}
            toggle={true}
            menuIndex={menuIndex}
          />
        )}
      </li>
    );

    const SubMenu = ({ dept, data, toggle, menuIndex }) => {
     

      dept = dept + 1;

      return (
        <ul>
          {data.map((menu, index) => {
            const menuName = `parent-submenu-${dept}-${menuIndex}-${index}`;
            return (
              <ListMenu
                dept={dept}
                data={menu}
                hasSubMenu={menu.submenu.length ? menu.submenu : false}
                menuName={menuName}
                key={menuName}
                menuIndex={index}
              />
            );
          })}
        </ul>
      );
    };



    return (

      <Fragment>

        <section className="faq">

          <div className="container">

            <div className="inner-content">

              <ul className="icab-sitemap">

                {sitemap && sitemap.section && <Fragment>

                  {sitemap.section.contents.map((menu, index) => {
                    const dept = 1;
                    const menuName = `parent-menu-${dept}-${index}`;

                    return (
                      <ListMenu
                        dept={dept}
                        data={menu}
                        hasSubMenu={menu.submenu.length ? menu.submenu : false}
                        menuName={menuName}
                        key={menuName}
                        menuIndex={index}
                      />
                    );
                  })}


                  {/* <li>


                          <Link to={{ pathname: row.url ? row.url : '' }}>
                            {lang == 'en' && <Fragment>{row.title} </Fragment>}
                            {lang == 'bn' && <Fragment>{row.bn_title} </Fragment>}

                          </Link>
                          {row.submenu.length > 0 && <ul>
                            {row.submenu.map((srow, skey) => {
                              return (
                                <Fragment key={skey}>
                                  {srow.link_type == 1 && <li key={skey}>

                                    {(srow.page.layout_id == 3 || srow.page.layout_id == 6) && <Fragment>

                                      <Link to={{ pathname: srow.page ? '/page'+srow.page.slug : '' }}>
                                        {lang == 'en' && <Fragment>{srow.title} </Fragment>}
                                        {lang == 'bn' && <Fragment>{srow.bn_title} </Fragment>}

                                      </Link>
                                    </Fragment>}
                                    {!(srow.page.layout_id == 3 || srow.page.layout_id == 6) && <Fragment>

                                      <Link to={{ pathname: srow.page ? srow.page.slug : '' }}>
                                        {lang == 'en' && <Fragment>{srow.title} </Fragment>}
                                        {lang == 'bn' && <Fragment>{srow.bn_title} </Fragment>}

                                      </Link>
                                    </Fragment>}

                                  </li>}
                                  {srow.link_type == 0 && <li key={skey}>
                                    <Link to={{ pathname: srow.url ? srow.url : '' }} target={srow.url_target}>
                                      {lang == 'en' && <Fragment>{srow.title} </Fragment>}
                                      {lang == 'bn' && <Fragment>{srow.bn_title} </Fragment>}

                                    </Link>
                                  </li>}
                                </Fragment>
                              )
                            })}
                          </ul>}


                        </li> */}


                  

                </Fragment>}



              </ul>

            </div>

          </div>

        </section>
      </Fragment>

    );
  }
}

const mapStateToProps = (state) => ({
  layout_id: state.utils.layout_id,
  lang: state.utils.lang
})

const mapDispatchToProps = (dispatch) => ({
  // getGallerys: (requestData) => dispatch(getGallerys(requestData)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Sitemap));