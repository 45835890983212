import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { image_url } from "../../config/Api";


export const FundMiddle = ({ data}) => {
    return (
        <Fragment>

            

            

            <div class="fund-middle-content bdventure-fund">
                <div class="container">

                {data && data.map((row,key)=>{
                    return(
                        <Fragment>
                            <div class="row">
                                <div class="col-md-5">
                                    <div class="fund-middle-content-banner">
                                        <img src={image_url+''+row.listcontent.image} alt=""></img>
                                    </div>
                                </div>
                                <div class="col-md-7">
                                    <div class="fund-middle-content-block">
                                        <div class="row">
                                        {row.list && row.list.map((prow,key)=>{

                                            return(
                                                <Fragment>
                                                        <div class="col-md-6">
                                                            <div class="fund-block-single">
                                                                <div class="fund-block-icon">
                                                                    <img src={image_url+''+prow.image} alt=""></img>
                                                                </div>
                                                                <h4>{prow.description ? prow.description : ''}</h4>
                                                                <p>{prow.title ? prow.title : ''}</p>
                                                            </div>
                                                        </div>

                                                </Fragment>
                                            )
                                        })}
                                            
                                            
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    )
                })}
                    
                    
                </div>
            </div>

        </Fragment>
    )
}

