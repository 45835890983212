import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { image_url } from "../../config/Api";
import Img2 from '../../img/icon/icon2.svg';



export const HowWeWork = ({data}) => {
    return (
        <Fragment>
            {data && data.map((row,key)=>{
                return(
                    <Fragment>

                        <section class="how-we-work hidden">
                            <div class="overlay"></div>
                                <div class="container">
                                    <div class="title-white text-center">
                                        <h1>{row.listcontent.description}</h1>
                                    </div>
                                    <div class="row">
                                        
                                        {row.list && row.list.map((prow,key)=>{

                                            return(
                                                <Fragment>

                                                <div class="col-lg-3 col-md-6 col-sm-6">
                                                    <div class="how-we-work-single">
                                                        <div class="how-we-work-banner">
                                                            <img src={image_url+''+prow.image} alt=""></img>
                                                        </div>
                                                        <h3>{prow.title}</h3>
                                                        <p>{prow.description}</p>
                                                    </div>

                                                </div>


                                                </Fragment>
                                            )
                                        })}
                    
                                    </div>
                                </div>
                        </section>

                        <section class="educlerk__module__section pt-70 pb-120">
                            <div class="container">
                                <div class="title-style-one">
                                    <h3> Educlerk Modules</h3>
                                    <p>
                                        The software is usable in all organization
                                    </p>
                                </div>
                                <div class="module__slider  owl-carousel mt-30">
                                    <div>
                                        <div class="module__slider__item">
                                            <img src={Img2} alt="" height="45px" class="mb-35"></img>
                                            <h5 class="mb-10">For Admin</h5>
                                            <h3 class="mb-0">Accounting</h3>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="module__slider__item">
                                            <img src={Img2} alt="" height="45px" class="mb-35"></img>
                                            <h5 class="mb-10">Guardians</h5>
                                            <h3 class="mb-0">Attendance</h3>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="module__slider__item">
                                            <img src={Img2} alt="" height="45px" class="mb-35"></img>
                                            <h5 class="mb-10">Student</h5>
                                            <h3 class="mb-0">Result System</h3>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="module__slider__item">
                                            <img src={Img2} alt="" height="45px" class="mb-35"></img>
                                            <h5 class="mb-10">More Details</h5>
                                            <h3 class="mb-0">Accounting</h3>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="module__slider__item">
                                            <img src={Img2} alt="" height="45px" class="mb-35"></img>
                                            <h5 class="mb-10">For Admin</h5>
                                            <h3 class="mb-0">Accounting</h3>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="module__slider__item">
                                            <img src={Img2} alt="" height="45px" class="mb-35"></img>
                                            <h5 class="mb-10">More Details</h5>
                                            <h3 class="mb-0">Accounting</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </Fragment>
                )
            })}


        </Fragment>
    )
}

