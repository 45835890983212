import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Link } from "react-router-dom";
import { getAllNewsEvent, setLayout, setPath, setPathLayout } from '../../actions/Utils';
import Htmlcontent from '../../components/Htmlcontent';
import { image_url } from '../../config/Api';
// import NoImage from '../../img/event.png';

class SeeAllNews extends Component {

  constructor(props) {
    super(props);
    this.state = {
      event:[]
    }

  }

  componentDidMount = () => {
   
    this.props.getAllNewsEvent(this.props.category).then((data) => {
        this.setState({
          event:data
        })
    });
}

  static getDerivedStateFromProps(props, state) {

    return false;
  }
  render() {
    
   // console.log("event landing = ",this.state);
    const {event} = this.state;
    return (

      <section className="our-latest-news">

    
            <div className="container">

              <div className="row">


                {event.length && <Fragment>
                  {event.map((event_row, ekey) => {
                    return (
                      <Fragment key={ekey}>

                        <div className="col-md-4">
                          <div className="our-latest-news-single">
                            
                           

                            <img src={event_row.image ? image_url+event_row.image : ''}  alt="Banner"/>
                            

                            <div className="news-date">
                              <h3>{event_row.publish_date_format}</h3>
                            </div>

                            <div className="our-latest-news-content">

                              <h6>{event_row.category?event_row.category.name:''}</h6>

                            <h3>
                              <Link to={{
                                  pathname: `${process.env.PUBLIC_URL}${event_row.slug}`
                                }}>
                                  {event_row.title.length >100&&<Fragment>{event_row.title.substring(0,100)}...</Fragment>}
                                  {event_row.title.length <100&&<Fragment>{event_row.title.substring(0,100)}</Fragment>}
                                
                                  </Link>
                            </h3>

                              <Htmlcontent data={event_row.short_description.substring(0,250)} />

                            </div>

                          </div>

                        </div>
                      </Fragment>
                    )
                  })}
                </Fragment>}


              </div>

        </div>

      </section>

    );
  }
}

const mapStateToProps = (state) => ({
  layout_id: state.utils.layout_id,
})

const mapDispatchToProps = (dispatch) => ({
  setPath: (requestData) => { dispatch(setPath(requestData)) },
  setLayout: (requestData) => { dispatch(setLayout(requestData)) },
  setPathLayout: (requestData) => { dispatch(setPathLayout(requestData)) },
  getAllNewsEvent: (requestData) =>  dispatch(getAllNewsEvent(requestData)) ,
})

export default connect(mapStateToProps, mapDispatchToProps)(SeeAllNews);