const contenerReducer = {
    home_layout:{
        content_loading:false,
        content:{}
    },
    faq_layout:{
        content_loading:false,
        content:{}
    },
    contactus_layout:{
        content_loading:false,
        content:{}
    },
    premiumcalculator_layout:{
        content_loading:false,
        content:{}
    },
    common_content:{
        content_loading:false,
        content:{}
    },
    content_loading:true,
    search_content:[],
    search_key:'',
    content_details:{},
    page_content:{
        content_loading:false,
        content:{}

    },
    news_content:{
        content_loading:false,
        content:{},
        category:[],
        filterdata:[],
    },
    committe_content:{
        groupcommitte:{
            content_loading:false,
            content:{},
        },
        singlecommitte:{
            content_loading:false,
            content:{},
        },
    }
}

export default(state = contenerReducer,action) =>{
    switch (action.type) {
        // case 'INIT_PAGE_NAME':
        //     var page = {};
            
        //     action.data.map((val,key)=>{
        //          if(val.slug){
        //             let slugs= val.slug.replace(/\s+/g, '_');
        //              page['page_'+slugs] = {content:{},content_loaded:false};
        //          }
        //          if(val.slug == null) {
        //              page['page_default'] = {content:{},content_loaded:false};
        //          }

        //          var total_length = Object.keys(page).length;
        //          var objectname = Object.keys(page)[total_length-1];

        //          val.submenu.map((subval,subkey)=>{
        //             if(subval.slug){
        //                 let slugs= subval.slug.replace(/\s+/g, '_');
        //                 page[objectname+'_sub_'+slugs] = {content:{},content_loaded:false};
        //              }
        //              if(subval.slug == null){
        //                  page[objectname+'_sub_default'] = {content:{},content_loaded:false};
        //              }
        //          })
        //     }) 
        
        //     return {
        //         ...state,
        //         ...page
        //     }
        case 'INIT_PAGE_NAME':
            var page = {};
            
            action.data.map((val,key)=>{
                page['page_'+val.id] = {content:{},content_loaded:false};
                val.submenu.map((subval,subkey)=>{
                    page['page_'+subval.id] = {content:{},content_loaded:false};
                })
            }) 
        
            return {
                ...state,
                ...page
            }
        case 'SET_SEARCH_KEY':
          
            return {
                ...state,
                search_key:action.search,
            }
        case 'INIT_SEARCH_CONTENT':
            return {
                ...state,
                search_content:action.data,
            }
       
        case 'ADD_SEARCH_CONTENT':
            return {
                ...state,
                search_content:[...state.search_content,...action.data],
            }
        case 'ADDED_CONTENT':
            var searchobj = 'page_'+action.id;
            var contentdata = state;
           
            Object.entries(state).map(layout => {
               
                if(layout[0] == searchobj) {
                  
                    contentdata[searchobj] = {content:action.data,content_loaded:true}
                } 
            });

            return {
                ...state,
                ...contentdata,
                content_loading:false,
            }
        case 'CONTENT_LOAD_STATUS':
            var searchobj = 'page_'+action.id;
            var contentdata = state;
           
            Object.entries(state).map(layout => {
                if(layout[0] == searchobj) {
                    contentdata[searchobj] = {content:contentdata[searchobj].content,content_loaded:true}
                } 
            });

            return {
                ...state,
                ...contentdata,
                content_loading:false,
            }
        case 'SET_DETAILS_CONTENT':
            
            return {
                ...state,
                content_details:action.data,
            }
        case 'INIT_HOME_LAYOUT':
           
            return {
                ...state,
                home_layout:{
                    ...state.home_layout,
                    content_loading:true,
                    content:action.data,
                }
            }
        case 'INIT_FAQ_LAYOUT':
           
            return {
                ...state,
                faq_layout:{
                    ...state.faq_layout,
                    content_loading:true,
                    content:action.data,
                }
            }
        case 'INIT_CONTACTUS_LAYOUT':
            console.log("Hello Mobarak contactus_layout=",action.data);
            return {
                ...state,
                contactus_layout:{
                    ...state.contactus_layout,
                    content_loading:true,
                    content:action.data,
                }
            }
        case 'INIT_PREMIUM_CALCULATOR_LAYOUT':
           
            return {
                ...state,
                premiumcalculator_layout:{
                    ...state.contactus_layout,
                    content_loading:true,
                    content:action.data,
                }
            }
        case 'INIT_COMMON_CONTENT':
           //YOU CAN LOAD ANY LAYOUT CONTENT LOAD IN THIS OBJECT. 
            return {
                ...state,
                common_content:{
                    ...state.contactus_layout,
                    content_loading:true,
                    content:action.data,
                }
            }
            case 'INIT_PAGE_LAYOUT':
                return {
                    ...state,
                    page_content:{
                        ...state.page_content,
                        content_loading:true,
                        content:action.data,
                    }
                }
                case 'INIT_NEWS_LAYOUT':
                    return {
                        ...state,
                        news_content:{
                            ...state.news_content,
                            content_loading:true,
                            content:action.data,
                        }
                    }
                case 'INIT_WEB_NEWS_CATEGORY':
                    return {
                        ...state,
                        news_content:{
                            ...state.news_content,
                            category:action.data,
                        }
                    }
                case 'INIT_WEB_NEWS_FILTER':
                    return {
                        ...state,
                        news_content:{
                            ...state.news_content,
                            filterdata:action.data,
                        }
                    }
                case 'INIT_GROUPOF_COMMITTE':
                    return {
                        ...state,
                        committe_content:{
                            ...state.committe_content,
                            groupcommitte:{
                                content_loading:false,
                                content:action.data
                            },
                        }
                    }
                case 'INIT_SINGLEOF_COMMITTE':
                    return {
                        ...state,
                        committe_content:{
                            ...state.committe_content,
                            singlecommitte:{
                                content_loading:false,
                                content:action.data
                            },
                        }
                    }
               
        default:
            return state;
            
    }
}