import React, { Fragment } from 'react';
import InnerHTML  from 'dangerously-set-inner-html'
function Htmlcontent(props) {
    return (
        <Fragment>
                 <InnerHTML html={props.data} />
        </Fragment>
                
        
    );
}

export default Htmlcontent;