import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { image_url } from "../../config/Api";
import Img1 from '../../img/icon/icon1.svg';
import Img2 from '../../img/icon/icon2.svg';
import Img3 from '../../img/icon/icon3.svg';
import Img4 from '../../img/icon/icon4.svg';
import Img5 from '../../img/icon/icon5.svg';
import Img6 from '../../img/icon/icon6.svg';


export const OurExpertise = ({data}) => {
    return (
        <Fragment>

        {data && data.map((row,key)=>{
            return(
                <Fragment>
                    <section class="our-expertise hidden">
                        <div class="container">
                            <div class="title-black text-center">
                                <h1>{row.listcontent.description}</h1>
                            </div>
                            <div class="row">
                                
                                {row.list && row.list.map((prow,key)=>{

                                    return(
                                        <Fragment>

                                            <div class="col-lg-4 col-md-6 col-sm-6">
                                                <div class="expertise-single">
                                                    <img src={image_url+''+prow.image} alt=""></img>
                                                    <h3>{prow.title}</h3>
                                                    <p>{prow.description}</p>
                                                </div>
                                            </div>


                                        </Fragment>
                                    )
                                })}

                                            
                                
                                
                                
                            </div>
                        </div>
                    </section>

                    
                </Fragment>
            )
        })}

        </Fragment>
    )
}

