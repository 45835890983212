import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { image_url } from "../../config/Api";


export const FundPartner = ({ data}) => {
    return (
        <Fragment>

            <div class="fund-partner">
                <div class="container">
                    <div class="row">
                    {data && data.map((row,key)=>{
                        return(
                                <Fragment>
                                    {row.list && row.list.map((prow,key)=>{

                                        return(
                                            <Fragment>

                                                <div class="col-md-3">
                                                    <div class="fund-partner-single">
                                                    {prow.external_link ?
                                                        <a target='_blank' href={prow.external_link}>
                                                            <img src={image_url+''+prow.image} alt="">

                                                            </img>
                                                        </a>
                                                        :
                                                        <img src={image_url+''+prow.image} alt="">

                                                        </img>
                                                    }
                                                    {prow.external_link ?
                                                        <a target='_blank' href={prow.external_link}>
                                                            <p>{prow.title ? prow.title : ''}</p>
                                                        </a>
                                                        :
                                                        <p>{prow.title ? prow.title : ''}</p>
                                                    }

                                                        
                                                    </div>
                                                </div>

                                            </Fragment>
                                        )
                                    })}

                                </Fragment>
                            )
                    })}
                        
                        
                        
                    </div>
                </div>
            </div>


        </Fragment>
    )
}

