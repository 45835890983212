import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter,Link } from "react-router-dom";
import { pageSectionContent,PageContent, getSetting, setPathLayout, getCorporateSponsorDetails } from "../../actions/Utils";
import Htmlcontent from '../../components/Htmlcontent';
import { image_url, host_name } from '../../config/Api';
import BannerLeft from '../page/BannerLeft';


class CorporateSponsorDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            path: '',
            contents: null,
            page_content:{},
            content_load: false
        };
    }
    componentDidMount = () => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        const { params } = this.props.match;

        const slugpage = "corporate-sponsor";

       // console.log(slug);

        this.props.PageContent(slugpage).then((res)=>{

        });

        this.props.getCorporateSponsorDetails({ slug: params.slug }).then((data) => {
            console.log("pageSectionContent =", data);
            this.setState({
                contents: data,
                content_load: true
            })
        });

    }

    setPathLayout = (parms) => {
        this.props.setPathLayout(parms);
    }
    getSetting = async () => {
        let data = await this.props.getSetting();
    }
    static getDerivedStateFromProps(props) {
        return {
            path: props.utils.path,
            header: props.header,
            container: props.container,
            footer: props.footer,
            layout_id: props.utils.layout_id,
            lang: props.lang
        };
    }


    render() {

        // console.log("common state =", this.state)
        const { contents, content_load } = this.state;

        const {page_data}=this.props.page_content.content;


        console.log('pagesection contents=', contents);

        return (
            <Fragment>
                {/* <BannerLeft title={contents ? contents.title : ''} menu_name={''} banner={''} /> */}
                {page_data && <Fragment>
                
                {contents && contents.length ? <Fragment>
                            {contents.map((row, key) => {
                                return (

                                    
                                    <Fragment>

                                        {/* <section class="inner-header">
                                            <img src={`${host_name}img/innerbaner.png`} alt=""></img>
                                            <h1>Corporate Sponsor</h1>
                                            <div class="inner-breadcrumb">
                                                <ul class="nav justify-content-center">
                                                    <li> <Link to={{ pathname: `/home` }} >Home</Link></li>
                                                    <li><Link to={{ pathname: `/about` }} >Corporate Sponsor</Link></li>
                                                    <li>{row.title}</li>
                                                </ul>
                                            </div>
                                            <div class="overlay"></div>
                                        </section> */}
                                        
                                        {page_data && <Fragment>

                                        <section class="inner-header">
                                            <img src={page_data.image ? image_url + page_data.image : `${host_name}img/innerbaner.png`} alt=""></img>
                                            <h1>{row.title}</h1>
                                            <div class="inner-breadcrumb">
                                                <ul class="nav justify-content-center">
                                                    <li><Link to={{ pathname: `/home` }} >Home</Link></li>
                                                    <li><Link to={{ pathname: `/about` }} >About</Link></li>
                                                    
                                                    <li>{row.title}</li>
                                                </ul>
                                            </div>
                                            <div class="overlay"></div>
                                        </section>

                                        </Fragment> }

                                        <section class="sponsor">
                                            <div class="container">
                                                <div class="row">
                                                    <div class="col-md-8">
                                                        <div class="sponsor-left mb-5">
                                                        
                                                        {row.description &&  <Htmlcontent data={row.description} /> }
                                                        
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                           
                                                        <div class="sponsor-right mb-5">
                                                            
                                                            {row.image  &&   <img src={`${image_url}${row.image}`} alt="" /> }
                                                           
                                                            {row.sponsor_ceo && <Fragment>
                                                            <h3>CEO </h3>
                                                            <p>
                                                                {row.sponsor_ceo}
                                                            </p>
                                                            </Fragment>}
                                                            {row.sponsor_inception && <Fragment>
                                                            <h3>Inception</h3>
                                                            <p>{row.sponsor_inception}</p>
                                                            </Fragment>}

                                                            {row.sponsor_industry && <Fragment>
                                                            <h3>Industry</h3>
                                                            <p>{row.sponsor_industry}</p>
                                                            </Fragment>}

                                                            {row.sponsor_status && <Fragment>
                                                            <h3>Status</h3>
                                                            <p>{row.sponsor_status}</p>
                                                            </Fragment>}
                                                            
                                                            
                                                            
                                                            
                                                            <div class="sponsor-social">

                                                            {row.facebook && <a target='_blank' href={row.facebook}><i className="fab fa-facebook-f"></i>
                                                                        
                                                            </a>}

                                                            {row.website && <a target='_blank' href={row.website}><i className="fal fa-globe"></i>
                                                                        
                                                            </a>}

                                                            {row.linkedin && <a target='_blank' href={row.linkedin}><i className="fab fa-linkedin-in"></i> 
                                                                        
                                                            </a>}

                                                            {row.twitter &&  <a target='_blank' href={row.twitter}><i className="fab fa-twitter"></i>
                                                                       
                                                            </a>}
                                                            
                                                                
                                                            </div>
                                                        </div>

                       
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    
                                    </Fragment>

                                )
                        })}
                </Fragment> : ''}

                </Fragment> }
            </Fragment >
        );
    }
}

const mapStateToProps = state => ({
    page_content:state.contents.page_content,
    utils: state.utils,
    layout_id: state.utils.layout_id,
    setting: state.utils.setting,
    lang: state.utils.lang

})

const mapDispatchToProps = dispatch => ({
    getSetting: () => dispatch(getSetting()),
    setPathLayout: (requestData) => dispatch(setPathLayout(requestData)),
    PageContent: (requestData) => dispatch(PageContent(requestData)),
    pageSectionContent: (requestData) => dispatch(pageSectionContent(requestData)),
    getCorporateSponsorDetails: (requestData) => dispatch(getCorporateSponsorDetails(requestData)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CorporateSponsorDetails));