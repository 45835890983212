import React, { Fragment } from 'react';
import BannerLeft from '../BannerLeft';
import BreadCrumbs from '../BreadCrumbs';
import Content from '../Content';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { image_url } from "../../../config/Api";
import Htmlcontent from '../../../components/Htmlcontent';


const Common = ({ page_data }) => {
    const { title, bn_title } = page_data;
    /* const {contents :{short_description=""}={}}=page_data; */
    const { bread_curmbs } = page_data;
    const { image: banner_img = "" } = page_data;
    const { contents } = page_data;
    const { i18n } = useTranslation();
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });

      // Function to manipulate the slug
      const transformSlug = (slug) => {
        if (typeof slug !== 'string' || slug.length < 2) {
          return ''; // Handle edge cases where slug is not a string or too short
        }
        return slug.charAt(1).toUpperCase() + slug.slice(2);
      };

    console.log("page_data =",page_data);
    return (
        <Fragment>

            <section class="hero__section">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-7">
                            <div class="hero__left__conent">
                                <h1 class="mb-30">{page_data.title}</h1>
                                <p>{contents.short_description}</p>
                                <p  class="mb-70">Choose your modules based on your business functionality</p>
                                <a href="contact.php" class="btn btn-primary">Book a Demo
                                    <i class="fa fa-light fa-angle-right ps-1"></i>
                                </a>
                            </div>
                        </div>
                        <div class="col-lg-5">
                            <div class="hero__content__right d-none d-sm-block  pl-30">
                                <img src={image_url+''+page_data.image} alt=""></img>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="breadcrumb">
                <div class="container">
                    <ul class="nav">
                        <li>
                            <Link     to={{ pathname: `/` }} >Home</Link>
                        </li>
                        <li>
                           {transformSlug(page_data.slug)}
                        </li>
                    </ul>
                </div>
            </section>


            {contents.description &&<Fragment>
                    <Htmlcontent data={contents.description} />
            </Fragment>}




            
        </Fragment>
    )
}

export default Common
