import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from "react-router-dom";
import { faqLayoutContent,PageContent, getSetting, homeLayoutContent, setPathLayout } from "../actions/Utils";
import '../App.css';
import Htmlcontent from '../components/Htmlcontent';
import BannerLeft from '../components/page/BannerLeft';
import BreadCrumbs from '../components/page/BreadCrumbs';
import { image_url,host_name } from '../config/Api';



class Faq extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: '',
      header: {},
      container: {},
      page_content:{},
      footer: {},
      layout_id: '',
      faqlayout: null,
    };
  }
  componentDidMount = () => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    const { location } = this.props.history;
    var { pathname } = location
    const slugpage = this.props.match.path.replace('/','');
    this.props.PageContent(slugpage).then((res)=>{

    });

    this.props.faqLayoutContent({ 'slug': pathname });

  }

  setPathLayout = (parms) => {
    this.props.setPathLayout(parms);
  }
  getSetting = async () => {
    let data = await this.props.getSetting();
  }
  static getDerivedStateFromProps(props) {
    return {
      path: props.utils.path,
      header: props.header,
      container: props.container,
      footer: props.footer,
      layout_id: props.utils.layout_id,
      faqlayout: props.faqlayout
    };
  }
  getContentData = (menu_id) => {

    const requestData = {
      menu_id: menu_id,
    }
    this.props.sectionandContent(requestData);
  }

  render() {


    const faqlayout = this.props.faqlayout.content;
    var top_html = '';
    var question = [];
    var browse_otherfaq = '';

    const {page_data}=this.props.page_content.content;

    if (faqlayout) {
      const pagesection = faqlayout.pagesection;


      if (pagesection) {
        const section_data = pagesection.filter((row, key) => {

          if (row.section.section_type.toLowerCase() == 'faq module') {
            question = row.section.contents;
          }
          // else if (row.section.name.toLowerCase() == 'faq question') {
          //   question = row.section;
          // }

          // else if (row.section.name.toLowerCase() == 'faq browse more faq page') {
          //   browse_otherfaq = row.section;
          // }
          // else {
          //   return false;
          // }

        });
      }


    }


    const FaqItem = ({ data, pkey, ckey }) => {
      console.log("faq data =", data);
      return (
        <Fragment>
          <div class="card-header collapsed" data-toggle="collapse" href={`#collapse_${pkey}_${ckey}`}>
            <a class="card-title">
              {data.question}
            </a>
          </div>
          <div id={`collapse_${pkey}_${ckey}`} class="card-body collapse" data-parent={`#accordion`}>
            <p>
              {data.answer}
            </p>
          </div>
        </Fragment>
      )
    }


    console.log("faqlayout =", faqlayout);
    console.log("faqlayout question =", question);

    return (
      <Fragment>
        {/* <section className="innerpage-header">
          {top_html && <Fragment>
            <Htmlcontent data={top_html.contents.description} />

          </Fragment>}

        </section> */}

        {/* {faqlayout.image && <section className="innerpage-header">

          <img src={`${image_url}${faqlayout.image}`} />

        </section>} */}

        {/* {Object.keys(faqlayout).length ? <BannerLeft title={faqlayout.title} menu_name={''} banner={faqlayout.image} /> : <BannerLeft title={'Faq'} menu_name={''} banner={''} />} */}

        {/* {faqlayout.bread_cumbs&&<BreadCrumbs bread_curmbs={faqlayout.bread_cumbs} />} */}

        {page_data && <Fragment>
        
        {page_data && <Fragment>

        <section class="inner-header">
            <img src={page_data.image ? image_url + page_data.image : `${host_name}img/innerbaner.png`} alt=""></img>
            <h1>{page_data.title}</h1>
            <div class="inner-breadcrumb">
                <ul class="nav justify-content-center">
                    <li><Link to={{ pathname: `/home` }} >Home</Link></li>
                    <li>{page_data.title} </li>
                </ul>
            </div>
            <div class="overlay"></div>
        </section>

        </Fragment> }

        <section class="p-bottom-110">

        <div class="tab tab--7 mb-5 people">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="tab_nav7">
                            <ul class="nav justify-content-center" id="tab7" role="tablist">
                            { question.length > 0 ?
                                question.map((row,key) => { 
                                    return(  
                                        
       
                                        <Fragment>

                                        <li class="nav-item">
                                            <a  className={`nav-link ${key == 0 ? 'active' : ''}`} id={`tab7_nav${key}`}  data-toggle="tab" href={`#tab7_content${key}`}   role="tab" aria-controls={`tab7_nav${key}`}  aria-selected="true">{row.name}</a>
                                        </li>


                                        </Fragment>
                                          

                                          )
                                      
                                      })
                                      :
                                      ''
                                      }
                                
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="tab-content tab--content7" id="tabcontent7">
                          { question.length > 0 ?
                            question.map((row,key) => { 
                                return(  
     
                                  <Fragment>
                                    <div  className={`tab-pane fade ${key == 0 ? 'show active' : ''}`} id={`tab7_content${key}`}  role="tabpanel" aria-labelledby={`tab7_nav${key}`} >
                                        <div class="accordion-styles accordion--one">
                                            <div class="container">
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <div className={`accordion accordion_one`} id={`accordion_${key}`}>
                                                        {row.faq.length ? <Fragment>
                                                          {row.faq.map((prow, pkey) => {
                                                            return (
                                                            <div class="accordion-single">
                                                                <div class="accordion-heading" id={`heading${key}${pkey}`}>
                                                                    <h6 class="mb-0">
                                                                        <a href="#" data-toggle="collapse" data-target={`#collapse${key}${pkey}`}  aria-expanded={`${pkey == 0 ? 'true' : 'false'}`} aria-controls={`collapse${key}${pkey}`} >
                                                                            {prow.question}
                                                                        </a>
                                                                    </h6>
                                                                </div>
                                                                <div id={`collapse${key}${pkey}`} className={`collapse ${pkey == 0 ? 'show' : ''}`}  aria-labelledby={`heading${key}${pkey}`}  data-parent={`#accordion_${key}`}>
                                                                    <div class="accordion-contents">
                                                                        <Fragment>
                                                                          {/* {prow.answer}  */}
                                                                          {prow.answer &&  <Htmlcontent data={prow.answer} /> }
                                                                        </Fragment>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                          )
                                                        })}
                                                        </Fragment> : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> 
                                    </div>
    
                                    
                                  </Fragment>
                                            

                                )
                                
                          })
                          :
                          ''
                          }
                        </div>
                    </div>
                </div>
            </div>
            
        </div>

        
        </section>

        </Fragment> }
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  page_content:state.contents.page_content,
  faqlayout: state.contents.faq_layout,
  utils: state.utils,
  header: state.header,
  contents: state.contents,
  footer: state.footer,
  layout_id: state.utils.layout_id,
  routemenu: state.utils.routemenu,
  setting: state.utils.setting,

})

const mapDispatchToProps = dispatch => ({
  getSetting: () => dispatch(getSetting()),
  setPathLayout: (requestData) => dispatch(setPathLayout(requestData)),
  PageContent: (requestData) => dispatch(PageContent(requestData)),
  homeLayoutContent: (requestData) => dispatch(homeLayoutContent(requestData)),
  faqLayoutContent: (requestData) => dispatch(faqLayoutContent(requestData)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Faq));