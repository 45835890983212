import React, { Fragment } from 'react';
import BannerLeft from '../BannerLeft';
import BreadCrumbs from '../../page/BreadCrumbs';
import Member from '../Member';



const Common = ({ page_data, member, lang }) => {
    const { title, bn_title } = page_data;
    const { contents: { short_description = "", bn_short_description = "" } = {} } = page_data;
    const { bread_curmbs } = page_data;
    const { image: banner_img = "" } = page_data;
    const { contents } = page_data;
    return (
        <Fragment>

            {/* {lang === 'en' && <BannerLeft title={title} short_description={short_description} menu_name={bread_curmbs[0].title} banner={banner_img} />}
            {lang === 'bn' && <BannerLeft title={bn_title} short_description={bn_short_description} menu_name={bread_curmbs[0].bn_title} banner={banner_img} />} */}



            <BreadCrumbs bread_curmbs={bread_curmbs} />
            <section className="board-of-director">

                <div className="container">

                    <div className='page-title'>
                        <h1>
                            {lang === 'en' && <Fragment>{title}</Fragment>}
                            {lang === 'bn' && <Fragment>{bn_title} </Fragment>}
                        </h1>
                    </div>

                    <div className="row justify-content-center">

                        <div className="row">

                            <Member member={member} lang={lang} />
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default Common
