import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link,withRouter } from "react-router-dom";
import { getSetting, PageContent, setPathLayout,getFundDocumentData,getFundPerformanceData } from "../actions/Utils";

import { image_url, host_name } from '../config/Api';
import Htmlcontent from '../components/Htmlcontent';
import BannerLeft from '../components/page/BannerLeft';

import {FundMiddle} from '../components/fund/FundMiddle';
import {FundPartner} from '../components/fund/FundPartner';
import {FundDocument} from '../components/fund/FundDocument';
import {FundTable} from '../components/fund/FundTable';






class Fund extends Component {
    constructor(props) {
        super(props);
        this.state = {
            path:'',
            header:{},
            container:{},
            footer:{},
            layout_id:'',
            page_content:{},
            show_form:false,
            menu_open: false,
            fund_document:{},
            fund_history:{}
            
        };
    }

    

    componentDidMount = () => {
        // console.log('hello world');
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });

        const {location} = this.props.history;
        const slug = this.props.match.path.replace('/','');

       console.log(slug);

        this.props.PageContent(slug).then((res)=>{

        });

        
        
        this.props.getFundDocumentData({slug: slug }).then((data) => {
           
            this.setState({
                fund_document: data
                
            })
            
        });

        this.props.getFundPerformanceData({slug: slug }).then((data) => {
           
            this.setState({
                fund_history: data
                
            })
            
        });

        
    }

    render() {
        const content = this.props.page_content.content;
        const {page_data}=this.props.page_content.content;

        const {fund_document,fund_history} = this.state;

        // console.log('content =',content);

        var contents_top = [];
        var contents_middle = [];
        var contents_bottom_list = [];
        var section_data_top = {};
        var section_data_middle = {};
        var section_data_bottom_list = {};

        // This technique is used Because page Edit Section Are not Sorted
        if(page_data){

            const {section} = page_data;
            
            if(section){
            section_data_top = section.find((row) => {

                if (row.type) {
                    if (row.type === 'html') {
                        return true;
                    }
                    else {
                        return false;
                    }
                }

                else {
                    return false;
                }
            });

            

            if (section_data_top) {
                contents_top = section_data_top.contents[0]['description']; //about top content load

            }
            
            // console.log('contents_top',contents_top);

            section_data_middle = section.find((row) => {

                if (row.typename) {
                    if (row.typename === 'fund middle') {
                        return true;
                    }
                    else {
                        return false;
                    }
                }

                else {
                    return false;
                }
            });

            if (section_data_middle) {
                contents_middle = section_data_middle.contents; //middle list
                
                

            }

            section_data_bottom_list = section.find((row) => {

                if (row.typename) {
                    if (row.typename === 'fund partner') {
                        return true;
                    }
                    else {
                        return false;
                    }
                }

                else {
                    return false;
                }
            });

            if (section_data_bottom_list) {
                contents_bottom_list = section_data_bottom_list.contents; //bottom list
                
                

            }

     

        } 
        }
        
        

        
      

        // console.log('contents_top =',contents_top);
        // console.log('contents_middle =',contents_middle);
        // console.log('contents_middle =',contents_bottom_list);
        
        return (

            

          <Fragment>

                

            {page_data && <Fragment>

            <section class="inner-header">
                <img src={page_data.image ? image_url + page_data.image : `${host_name}img/innerbaner.png`} alt=""></img>
                <h1>{page_data.title}</h1>
                <div class="inner-breadcrumb">
                    <ul class="nav justify-content-center">
                        <li><Link to={{ pathname: `/home` }} >Home</Link></li>
                        
                        <li>{page_data.title} </li>
                    </ul>
                </div>
                <div class="overlay"></div>
            </section>

            </Fragment> }

                       
            <Fragment>
            
            
            

            <section class="fund ">

                <div class="container">
                    {contents_top &&<Fragment>
                                <Htmlcontent data={contents_top} />
                    </Fragment>}
                    
                </div>

                {contents_middle &&<Fragment>
                    <FundMiddle data={contents_middle} />
                               
                </Fragment>}

                
                {contents_bottom_list &&<Fragment>
                    <FundPartner data={contents_bottom_list} />
                               
                </Fragment>}
               

                { fund_history.length > 0 ? <FundTable data={fund_history} />:''}

                

                { fund_document.length > 0 ? <FundDocument data={fund_document} />:''}

              
                
               
               
                

            
                
            </section>

            

            </Fragment>
                           
                       
                    


          </Fragment>

        );
      
           
        
   }
}

const mapStateToProps = state => ({
    page_content:state.contents.page_content,
    utils:state.utils,
    header:state.header,
    contents:state.contents,
    footer:state.footer,
    layout_id:state.utils.layout_id,
    routemenu:state.utils.routemenu,
    setting:state.utils.setting,
    lang:state.utils.lang
    
})

const mapDispatchToProps = dispatch => ({
    getSetting:() =>dispatch(getSetting()),
    setPathLayout:(requestData) =>dispatch(setPathLayout(requestData)),
    PageContent: (requestData) => dispatch(PageContent(requestData)),
    getFundDocumentData: (requestData) => dispatch(getFundDocumentData(requestData)),
    getFundPerformanceData: (requestData) => dispatch(getFundPerformanceData(requestData)),
})

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Fund));