import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import Slider from "react-slick";
// import "slick-carousel/slick/slick-theme.css";
// import "slick-carousel/slick/slick.css";
import { image_url } from '../../config/Api';
import Cookies from 'js-cookie';



export const Modules = ({data}) => {
    var slider = [];

        if (data) {

            const section_data = data.find((row) => {
                if (row.typename == 'Mobile apps Infinitely flexible') {
                    return true;
                }
                else {
                    return false;
                }
            });

            if (section_data) {
                slider = section_data.slider;
            }
        }

    var settings = {
            dots: false,
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2500,
            arrows: true,
            loop: true,
            margin: 30,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                },
                {
                    breakpoint: 300,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        console.log("am =",data); 

    return (

        <Fragment>
            <section class="mobile-apps section__padding">
                <div class="container">
                {data && data.map((row,key)=>{
                    console.log("asdasdb = ", row);
                    return(
                        <Fragment>
                            <h3>{row.listcontent.title}</h3>
                            <p>
                                {row.listcontent.description}
                            </p>


                            <div class="row mt-35">
                            {row.list && row.list.map((prow,key)=>{
                                return(
                                    <Fragment>
                                        <div class="col-lg-6">
                                            <div class="apps-single">
                                               
                                                <img src={image_url+''+prow.image} alt=""></img>
                                            </div>
                                        </div>
                                    </Fragment>
                                )
                            })}
                                
                            </div>
                        </Fragment>
                    )
                })}



                </div>
            </section>
        </Fragment>
    )
}

