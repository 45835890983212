import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link,withRouter } from "react-router-dom";
import { getSetting, NewsContent, PageContent, setPathLayout } from "../actions/Utils";


import BannerLeft from '../components/page/BannerLeft';
import { image_url, host_name } from '../config/Api';
import {ContentItem} from '../components/webcontent/ContentItem';
import Banner from '../img/banner3.png';
import Banner4 from '../img/banner4.jpg';



class WebContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            path:'',
            header:{},
            container:{},
            page_content:{},
            footer:{},
            layout_id:'',
            news_content:{},
            is_filter:false,
            filter_loading:false,
            filter_data:[],
            userfilterdata:{}
        };
        this.textInput = React.createRef();
    }
    componentDidMount = () => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        const {location} = this.props.history;
        const {slug} = this.props.match.params;

        const slugpage = this.props.match.path.replace('/','');

       // console.log(slug);

        this.props.PageContent(slugpage).then((res)=>{

        });
        
        this.props.NewsContent(slug).then((res)=>{

        });
        
        
    }
    componentDidUpdate=(prevProps)=>
    {
        const {slug} = this.props.match.params;
        const {slug:prevSlug} = prevProps.match.params;

        if(prevSlug!=slug)
        {

            this.props.NewsContent(slug).then((res)=>{

            });
        }
    }

    Filter = (requestdata) => {
        console.log('requestdata = ',requestdata);
        this.setState({
            filter_loading:true,
            is_filter:true,
            userfilterdata:requestdata
        })
        
    }
    handleContentLoad = (requestdata) => {
       
        this.setState({
            filter_loading:false,
            is_filter:false
        })
        
    }
    render() {
        const content=this.props.news_content.content;
         
        // const category = this.props.news_content.category;

        // const category = this.props.news_content.category;
        const {page_data}=this.props.page_content.content;
        // const {blog}=this.props.page_content.content[0].WebContent;
        // const blog_data = content[0].WebContent;

        // console.log('page_data=',page_data);

        // console.log('content news=',content[0].WebContent);

        return (
        
        <Fragment>

            {page_data && <Fragment>
                {content && <Fragment>
                    <div class="inner-header-bottom">
                        <div class="container pt-60 pb-15">
                            <div class="row align-items-center mt-30">
                                <div class="col-lg-6">
                                    <h3>{page_data.title}</h3>
                                    <p>
                                        Welcome to the educlerk.com blog, your hub for innovative education-related software solutions. Explore our articles to discover the latest trends, tips, and insights to enhance teaching, learning, and administrative processes. From classroom management tools to student engagement platforms, we're dedicated to empowering educators and learners alike. Stay informed, inspired, and ahead of the curve with our expertly curated content. Whether you're a teacher, administrator, or student, join us on the journey to revolutionize education through technology. Let's transform the way we teach and learn, one click at a time.
                                    </p>
                                </div>
                                <div class="col-lg-6 pr-50 pl-50 text-center">
                                    <img src={Banner} alt=""></img>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section class="pt-70 pb-70">
                        <div class="container">
                        
                            <div class="blog">
                                {content[0].WebContent && content[0].WebContent.map((prow,key)=>{
                                if(key == 0)
                                {
                                    return(
                                        <Fragment>

                                                    <div class="highlight-blog blog-box mb-50">
                                                        <div class="row align-items-center">
                                                            <div class="col-lg-7 order-2 order-sm-1">
                                                                <h3>{prow.title}</h3>
                                                                <p>{prow.short_description}</p>
                                                                <Link to={{pathname: `${process.env.PUBLIC_URL}/blogsDetails${prow.slug}`}} class="btn-readmore">Read More <i class="fa fa-angle-right"></i></Link>
                                                            </div>
                                                            <div class="col-lg-5 order-1  order-sm-2 mb-3 mb-sm-0">
                                                                
                                                                <img src={image_url+''+prow.image} alt=""></img>
                                                            </div>
                                                        </div>
                                                    </div>


                                        </Fragment>
                                    )
                                }
                                
                            })}


                              


                                <div class="row">
                                {content[0].WebContent && content[0].WebContent.map((prow,key)=>{
                                if(prow.id > 1)
                                {
                                    return(
                                        <Fragment>

                                    <div class="col-lg-4 mb-30">
                                        <div class="blog-box">
                                            <img src={image_url+''+prow.image} alt="" class="mb-20"></img>
                                            <h3>{prow.title}</h3>
                                            <p>{prow.short_description}</p>
                                            <Link to={{pathname: `${process.env.PUBLIC_URL}/blogsDetails${prow.slug}`}} class="btn-readmore">Read More <i class="fa fa-angle-right"></i></Link>
                                        </div>
                                    </div>
                                    </Fragment>
                                    )
                                }
                                
                            })}
                                   
                                </div>



                            </div>
                        </div>
                    </section>
                </Fragment> }
            </Fragment> }
        
        </Fragment>  

        
        );
        
    }
}

const mapStateToProps = state => ({
    page_content:state.contents.page_content,
    news_content:state.contents.news_content,
    utils:state.utils,
    header:state.header,
    contents:state.contents,
    footer:state.footer,
    layout_id:state.utils.layout_id,
    routemenu:state.utils.routemenu,
    setting:state.utils.setting,
    
})

const mapDispatchToProps = dispatch => ({
    getSetting:() =>dispatch(getSetting()),
    setPathLayout:(requestData) =>dispatch(setPathLayout(requestData)),
    PageContent: (requestData) => dispatch(PageContent(requestData)),
    NewsContent: (requestData) => dispatch(NewsContent(requestData)),
   

    
})

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(WebContent));