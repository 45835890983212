import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { image_url } from "../../config/Api";


export const InvestmentJourney = ({ data}) => {
    return (
        <Fragment>

 
            <div class="investment-journey accordion-styles accordion--three">
                <div class="container">
                    <div class="title-black">
                        <h1>Investment Journey</h1>
                    </div>
                    <div class="accordion accordion_four" id="accordion_four">
                        {data && data.map((row,key)=>{
                                    return(
                                        <Fragment>
                                            {row.list && row.list.map((prow,pkey)=>{

                                                return(
                                                    <Fragment>
                                                    
                                                        

                                                        <div class="accordion-single">
                                                            <div class="accordion-heading"  id={`accordion_four_heading${pkey}`} >
                                                                <h6 class="mb-0">
                                                                    <a href="#"  className={` ${pkey == 0 ? 'bg-gray-light' : 'collapsed bg-gray-light'}`}  data-toggle="collapse" data-target={`#accordion_four_collapse${pkey}`}  aria-expanded={`${pkey == 0 ? 'true' : 'false'}`} aria-controls={`accordion_four_collapse${pkey}`} >
                                                                        <div class="accordian-icon"><img src={image_url+''+prow.image}/></div> {prow.title ? prow.title : ''}
                                                                    </a>
                                                                </h6>
                                                            </div>
                                                            <div id={`accordion_four_collapse${pkey}`}  className={` ${pkey == 0 ? 'collapse show' : 'collapse'}`}   aria-labelledby={`accordion_four_heading${pkey}`}  data-parent="#accordion_four">
                                                                <div class="accordion-contents">
                                                                    <p>{prow.description ? prow.description : ''}</p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        
                                                        

                                                    </Fragment>
                                                )
                                            })}

                                        </Fragment>
                                    )
                        })}
                        
                          
                    </div>
                </div>
             </div>

        </Fragment>
    )
}

