import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { image_url } from "../../config/Api";
import Htmlcontent from '../../components/Htmlcontent';


export const Ecosystem = ({data}) => {
    return (
        <Fragment>
            {data && data.map((row,key)=>{
                return(
                    <Fragment>

                        <section class="our__clients section__padding">
                            <div class="container">
                                <div class="mb-25 text-center">
                                    <h3>{row.short_description}</h3>
                                    
                                    {row.description &&<Fragment>
                                        <Htmlcontent data={row.description} />
                                    </Fragment>}
                                </div>
                                <div id="our__clients">

                                    <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-5 row-cols-xl-5">
                                        
                                        {row.list && row.list.map((prow,key)=>{

                                            return(
                                                <Fragment>

                                                    <div class="col">
                                                        <div class="client-single">
                                                            <img src={image_url+''+prow.image} alt=""></img>
                                                        </div>
                                                    </div>
                                                </Fragment>
                                            )
                                        })}
                                        
                                    </div>

                                </div>

                            </div>
                        </section>
                    </Fragment>
                )
            })}


        </Fragment>
    )
}

