import React, { Fragment,useEffect } from "react";
import $ from 'jquery';
import { Link } from "react-router-dom";

import ReactDOM from 'react-dom'

import SmartDataTable from 'react-smart-data-table'

const testData = []







//   testData.push({
//     _id: 1,
//     fullName: 'test',
    
//     phone_number: '01714491616',
    
//   })


    // testData.push({
    //     _id: 1,
    //     fullName: 'test1',
        
    //     phone_number: '01714491616',
        
    //   })
    //   testData.push({
    //     _id: 1,
    //     fullName: 'test2',
        
    //     phone_number: '01714491616',
        
    //   })
    //   testData.push({
    //     _id: 1,
    //     fullName: 'test3',
        
    //     phone_number: '01714491616',
        
    //   })
    //   testData.push({
    //     _id: 1,
    //     fullName: 'test4',
        
    //     phone_number: '01714491616',
        
    //   })
    //   testData.push({
    //     _id: 1,
    //     fullName: 'test5',
        
    //     phone_number: '01714491616',
        
    //   })
    //   testData.push({
    //     _id: 1,
    //     fullName: 'test6',
        
    //     phone_number: '01714491616',
        
    //   })
  



export const FundTable = ({ data}) => {

    useEffect(() => {
        $(document).ready(function () {
            
            
                try {

                    var headers = document.getElementsByTagName('th'); 
                    headers[1].innerHTML = 'NAV/Unit (Cost Price)';
                    headers[2].innerHTML = 'NAV/Unit (Market Price)';
                    
                } catch {
                    // console.log("Error")
                }
           
        });
    });
    return (
        <Fragment>



        

        {/* {data && data.map((row,key)=>{
                return(
                    testData.push({
                        
                        date: row.date,
                        
                        cost_price: row.cost_price,
                        market_price: row.market_price,
                        
                      })
                    )
        })} */}

        <div class="perfomance-history">
            <div class="container">
                <div class="title-black text-center">
                    <h1>Performance History</h1>
                </div>
                <div class="m-auto d-block performance-table">
                    <div class="table-responsive text-center">

                    <SmartDataTable
                        data={data}
                        
                        className="table table-one"
                        perPage={5}
                        dynamic = {false}
                        
                    />
                        
                    </div>
                </div>
                
            </div>
        </div>

                

        </Fragment>
    )
}

