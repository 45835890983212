import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link,withRouter } from "react-router-dom";
import { getSetting, PageContent, setPathLayout } from "../actions/Utils";

import { image_url, host_name } from '../config/Api';
import Htmlcontent from '../components/Htmlcontent';
import BannerLeft from '../components/page/BannerLeft';

import {WhatWeSeek} from '../components/investment-process/WhatWeSeek';
import {PreferedSectors} from '../components/investment-process/PreferedSectors';
import {OurOfferings} from '../components/investment-process/OurOfferings';
import {InvestmentJourney} from '../components/investment-process/InvestmentJourney';



class InvestmentProcess extends Component {
    constructor(props) {
        super(props);
        this.state = {
            path:'',
            header:{},
            container:{},
            footer:{},
            layout_id:'',
            page_content:{},
            show_form:false,
            menu_open: false
            
        };
    }

    

    componentDidMount = () => {
        // console.log('hello world');
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });

        const {location} = this.props.history;
        const slug = this.props.match.path.replace('/','');

       // console.log(slug);

        this.props.PageContent(slug).then((res)=>{

        });

        
    }

    render() {
        const content = this.props.page_content.content;
        const {page_data}=this.props.page_content.content;

        var contents_top = [];
        var prefered_sectors = [];
        var what_we_seek = [];
        var our_offerings = [];
        var investment_journey = [];
        var section_data_top = {};
        var section_PreferedSectors = {};
        var section_WhatWeSeek = {};
        var section_OurOfferings = {};
        var section_InvestmentJourney = {};


        
        // This technique is used Because page Edit Section Are not Sorted
        if(page_data){

            const {section} = page_data;
            
            if(section){
            section_data_top = section.find((row) => {

                if (row.type) {
                    if (row.type === 'html') {
                        return true;
                    }
                    else {
                        return false;
                    }
                }

                else {
                    return false;
                }
            });

            

            if (section_data_top) {
                contents_top = section_data_top.contents[0]['description']; //about top content load

            }
            
            // console.log('contents_top',contents_top);

            section_PreferedSectors = section.find((row) => {

                if (row.name) {
                    if (row.name === 'Investment Process Prefered Sectors Section') {
                        return true;
                    }
                    else {
                        return false;
                    }
                }

                else {
                    return false;
                }
            });

            if (section_PreferedSectors) {
                prefered_sectors = section_PreferedSectors.contents; //preferedSectors

            }

            section_WhatWeSeek = section.find((row) => {

                if (row.name) {
                    if (row.name === 'Investment Process What We Seek Section') {
                        return true;
                    }
                    else {
                        return false;
                    }
                }

                else {
                    return false;
                }
            });

            if (section_WhatWeSeek) {
                what_we_seek = section_WhatWeSeek.contents; //what we seek
                
                

            }


            section_OurOfferings = section.find((row) => {

                if (row.name) {
                    if (row.name === 'Investment Process Our Offerings Section') {
                        return true;
                    }
                    else {
                        return false;
                    }
                }

                else {
                    return false;
                }
            });

            if (section_OurOfferings) {
                our_offerings = section_OurOfferings.contents; //our offering
                
            }

            section_InvestmentJourney = section.find((row) => {

                if (row.name) {
                    if (row.name === 'Investment Process Investment Journey Section') {
                        return true;
                    }
                    else {
                        return false;
                    }
                }

                else {
                    return false;
                }
            });

            if (section_InvestmentJourney) {
                investment_journey = section_InvestmentJourney.contents; // investment journey

            }

     

        } 
        }
        
        // console.log('here =');

        
      

        // console.log('page_data =',page_data);
        // console.log('contents_top =',contents_top);
        // console.log('prefered_sectors =',prefered_sectors);
        // console.log('what_we_seek =',what_we_seek);
        // console.log('our_offerings =',our_offerings);
        // console.log('investment_journey =',investment_journey);
        
        
        return (

            

          <Fragment>

                {page_data && <Fragment>

                {page_data && <Fragment>

                <section class="inner-header">
                    <img src={page_data.image ? image_url + page_data.image : `${host_name}img/innerbaner.png`} alt=""></img>
                    <h1>{page_data.title}</h1>
                    <div class="inner-breadcrumb">
                        <ul class="nav justify-content-center">
                            <li><Link to={{ pathname: `/home` }} >Home</Link></li>
                            <li>{page_data.title} </li>
                        </ul>
                    </div>
                    <div class="overlay"></div>
                </section>

                </Fragment> }

                

                        

                        
                <Fragment>
                
                
                <section class="fund investment-process mb-5">

                    {contents_top &&<Fragment>
                            <Htmlcontent data={contents_top} />
                    </Fragment>}

                    {prefered_sectors &&<Fragment>
                        <PreferedSectors data={prefered_sectors} />
                               
                    </Fragment>}

                    {what_we_seek &&<Fragment>
                        <WhatWeSeek data={what_we_seek} />
                               
                    </Fragment>}

                    {our_offerings &&<Fragment>
                        <OurOfferings data={our_offerings} />
                               
                    </Fragment>}

                    {investment_journey &&<Fragment>
                        <InvestmentJourney data={investment_journey} />
                               
                    </Fragment>}

                    

                    
                    

                    

                </section>

                

                </Fragment>
                            
                    

            </Fragment> }
          </Fragment>

        );
      
           
        
   }
}

const mapStateToProps = state => ({
    page_content:state.contents.page_content,
    utils:state.utils,
    header:state.header,
    contents:state.contents,
    footer:state.footer,
    layout_id:state.utils.layout_id,
    routemenu:state.utils.routemenu,
    setting:state.utils.setting,
    lang:state.utils.lang
    
})

const mapDispatchToProps = dispatch => ({
    getSetting:() =>dispatch(getSetting()),
    setPathLayout:(requestData) =>dispatch(setPathLayout(requestData)),
    PageContent: (requestData) => dispatch(PageContent(requestData)),
    // OurStoryData: (requestData) => dispatch(OurStoryData(requestData)),
})

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(InvestmentProcess));