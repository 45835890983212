
import React, { Fragment } from 'react';
import { Helmet } from "react-helmet";
import { getMetaData } from '../../actions/Utils'
import { withRouter } from "react-router-dom"
import { connect } from 'react-redux'

class HtmlHelmet extends React.Component {
        constructor(props) {
                super(props);
                this.state = {
                        slug: '',
                        meta_data: {}
                }
        }

        static getDerivedStateFromProps(props, state) {
                return true;

        }
        // componentDidUpdate = (prevProps,nextprops) => {
        //         console.log("prevProps data =",prevProps);
        //         console.log("nextprops data =",nextprops);
        //         const { slug } = this.props.match.params;
        //         const { slug: prevSlug } = prevProps.match.params;

        //         if (prevSlug != slug) {
        //                 const requestData = {
        //                         q: slug,
        //                 }
        //                 this.props.getMetaData(requestData).then((data) => {
        //                         this.setState({
        //                                 content: data,
        //                                 slug: slug
        //                         })
        //                 });
        //         }
        // }

        render() {

                const meta = this.props.custom_meta?this.props.custom_meta:this.props.meta_data;
                console.log("meta data ", meta);
                return (
                        <Helmet>
                                <title>{meta.meta_title}</title>
                                <meta name="description" content={meta.meta_description} />
                                <meta name="keywords" content={meta.meta_keywords} />
                        </Helmet>
                );
        }
}


const mapStateToProps = state => ({
        meta_data: state.utils.meta_data,
})

const mapDispatchToProps = dispatch => ({
        getMetaData: (requestData) => dispatch(getMetaData(requestData)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HtmlHelmet));