const HeaderReducer = {
    top_menu: [],
    header_menu: [],
    rightsite: [],
    popupmodalads:{}
}

export default (state = HeaderReducer, action) => {
    switch (action.type) {
        case 'INIT_HEADER':
            return {
                ...state,
                ...action.data
            }
        case 'INIT_TOPMENU':

            return {
                ...state,
                top_menu: action.data
            }
        case 'INIT_HEADERMENU':
            return {
                ...state,
                header_menu: action.data
            }
        case 'INIT_RIGHTSITE':
            return {
                ...state,
                rightsite: action.data
            }
        case 'INIT_MODAL_POPUP_ADS':
            return {
                ...state,
                popupmodalads: action.data
            }
        default:
            return state;

    }
}